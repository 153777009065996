import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addStudentEvidence,
  addStudentSignature,
  AssessmentSetToFinalService,
  finalSubmitPracticalAssessment,
  getPracticalAssessmentQuizQuestions,
  GetQuestionsService,
  GetQuizQuestionsService,
  setTosaveDraft,
  SubmitQuizService,
  SubmitSingleAnswerService,
  SubmitSingleAnswersService,
} from "../../../services/QuizService";
import { clearQuiz, setAssessmentReview, setPracAssessmentLaunched, setPracAssessSaved, setQuiz, setQuizComplete } from "../../../store/actions";
import ReactHtmlParser from "react-html-parser";
import DisplayQuestion from "./DisplayQuestionExam";
import Modal from "react-modal";
import Swal from "sweetalert2";
import PublicIP from "public-ip";
import Str from "../../common/Str";
import { useHistory } from "react-router-dom";
import { useMemo } from "react";
import { classStatusWidgets } from "../../common/Helper";
import { getRandomInt } from "../../../utils/commonFunction";
import { useFormik } from "formik";
import * as Yup from "yup";

function OptionList({ item, index, questionData, refresh, setrefresh }) {
  // const [opt, setopt] = useState(item.oid);
  const [option, setoption] = useState(item.oid);
  const dispatch = useDispatch();
  const onChange = (item) => {
    dispatch(setQuiz([item.qid, { ...questionData, selected: item.oid }]));
    setoption(item.oid);
    setrefresh(!refresh);
  };
  return (
    <div class="custom-control custom-radio">
      <input
        type="radio"
        // class="custom-control-input"
        id="customRadio"
        name="example"
        checked={
          parseInt(questionData.selected) == parseInt(item.oid) ? true : false
        }
        onChange={() => onChange(item)}
        value={item.oid}
      />
      <label class="custom-control-label" for="customRadio">
        {ReactHtmlParser(`${index + 1}) ${item.q_option}`)}
      </label>
    </div>
  );
}

const ViewQuestion = (props)=> {
  const dispatch = useDispatch();
  var interval = null;
  const { quiz } = useSelector((state) => state.quiz);
  const { examLaunched,isAssessmentReview,isPracAssessmentLaunched } = useSelector(state=>state.examLaunched);
  const quizData = Object.values(quiz);
  const keys = Object.keys(quiz);
  // const [modalIsOpen, setIsOpen] = React.useState(false);
  const [time, settime] = useState(1);
  const [question, setquestion] = useState(0);
  const [quesno, setquesno] = useState(1);
  const [refresh, setrefresh] = useState(false);
  const [unlimited, setunlimited] = useState(false);
  const [clear, setclear] = useState(false);
  const [showmodal, setshowmodal] = useState(false);
  const [questionData, setquestionData] = useState({});
  const [quizdesc, setquizdesc] = useState({});
  const [questionList, setquestionList] = useState([])
  const [optionData, setoptionData] = useState([]);
  const [starttime, setstarttime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [attempt_no, setattempt_no] = useState(0);
  const [duration_consumed, setduration_consumed] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  //state for practical assessment
  const [files, setFiles] = useState([]);
  const user = useSelector((state) => state.user);

  const {photoFiles,setDocFiles, setPhotoFiles,videoFiles,setVideoFiles,docFiles,lecturerObservations,setLecturerObservations,termCondition,setTermCondition}=props

  //signature 
  const [isSig, setIsSig] = useState("")
  const [ipAddress, setIpAddress] = useState('');
  const [cDate,setCDate] = useState('')
  const [emptyFields, setEmptyFields] = useState(false)
  const signaturePadRef = useRef(null);
  const fileInputRef = useRef(null);
  const [epochTime, setEpochTime] = useState('');
  const [readOnly, setReadOnly] = useState(isAssessmentReview && props.practicalAssessmentData?.header?.student_Signature ? true : false);
  const [readOnlyData, setReadOnlyData] = useState([])
  const [isSignature, setIsSignature]=useState(false)
  const [signatureType, setSignatureType]=useState(props.practicalAssessmentData?.header?.student_signature_type)
  const [isSignatureSave, setIsSignatureSave] = useState(0)

  const renameKeys = (data) => {

    if (!data) return {};
  
    const {
      student_Signature: student_signature = '#',
      student_Signature_Date: signature_date = '',
      student_Signature_Ip: ip = '',
      ...rest
    } = data;
  
    return {
      ...rest,
      student_signature,
      signature_date,
      ip,
    };
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      assessment_id: '',
      student_Signature: isSig,
      student_Signature_Ip: ipAddress,
      student_Signature_Date: epochTime,
      student_signature_type: signatureType,
      isSignatureUpload: isSignatureSave
    },
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append('student_Signature', isSig ? isSig : '');
      formData.append('student_Signature_Ip', ipAddress);
      formData.append('student_signature_type', signatureType !== null ? signatureType : 0);
      formData.append('student_Signature_Date', epochTime);
      formData.append('isSignatureUpload', 1);      
      formData.append('assessment_id', props.assessmentdetails?.id);

        finalSubmitPracticalAssessment(formData)
          .then(() => {
            if (isSignatureSave == 1) {
              Swal.fire({
                icon: "success",
                title: "Signature saved successfully!",
                text: '',
                timer: 3000,
                willOpen: () => Swal.showLoading(),
              }).then(() => {
                const updatedQuiz = quizData.find(q => q.qid === 'Student Declaration');
                if (updatedQuiz) {
                  updatedQuiz.student_Signature = isSig;
                  updatedQuiz.student_Signature_Date = epochTime;
                  updatedQuiz.student_Signature_Ip = ipAddress;
                }

                const updatedStudentQuiz = quizData.find(q => q.qid == "Student Declaration");

                // if (updatedQuiz) {
                //   dispatch(setQuiz(updatedQuiz));
                // }
                let newStudentDeclaration = renameKeys(updatedStudentQuiz)
                setReadOnlyData(newStudentDeclaration)
                setIsSignatureSave(0);
                props.initializeAssessment()
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Signature removed successfully!",
                text: '',
                timer: 3000,
                willOpen: () => Swal.showLoading(),
              }).then(() => {
                const updatedQuiz = quizData.find(q => q.qid === 'Student Declaration');
                if (updatedQuiz) {
                  updatedQuiz.student_Signature = null;
                  updatedQuiz.student_Signature_Date = "";
                  updatedQuiz.student_Signature_Ip = "";
                }
                // const updatedQuizList = quizData.map(q =>
                //   q.qid === 'Student Declaration' ? updatedQuiz : q
                // );
                // dispatch(setQuiz(updatedQuizList));
                setIsSignatureSave(0);
                props.initializeAssessment()
              });
            }
          }).catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'failed to update signature!',
            });

          })
          .finally(() => {
            // setSubmitLoading(false);
            setIsSignatureSave(0);
          });
    },
  });
  const formikData = useFormik({
    enableReinitialize: true,
    initialValues: {
      assessment_id: '',
      is_check_terms_and_conditions: termCondition,
      is_draft: null,
      is_Student_Signature_Required: props.practicalAssessmentData?.header?.is_Student_Signature_Required,
    },
    validationSchema: Yup.object().shape({
      is_check_terms_and_conditions: Yup.boolean().when('is_draft', {
        is: (val) => {
          return val == 0 || val === '0';
        },
        then: () => {
          return Yup.boolean()
            .oneOf([true], 'You must agree to the terms and conditions')
            .required('You must agree to the terms and conditions');
        },
        otherwise: () => {
          return Yup.boolean().notRequired();
        }
      })
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append('assessment_id', props.assessmentdetails?.id);
      formData.append('is_draft', values.is_draft);
      formData.append('is_check_terms_and_conditions', values.is_check_terms_and_conditions);

      if (values.is_draft == 1) {
        setSubmitLoading(true);
        finalSubmitPracticalAssessment(formData)
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Assessment saved successfully!",
              text: '',
              timer: 3000,
              willOpen: () => Swal.showLoading(),
            }).then(() => {
              props.initiateReload();
              props.setAssignmentReload(getRandomInt(5000));
              dispatch(setPracAssessmentLaunched(false));
              dispatch(setPracAssessSaved(true));
              props.stop(true);
              setReadOnly(true);
            });
          }).catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Assessment failed to save!',
            });
          })
          .finally(() => {
            setSubmitLoading(false);
          });
        return;
      } else {
        Swal.fire({
          title: 'Are you sure?',
          html: `${Str.setFinalPopup} ${''}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: Str.Submit,
        }).then((result) => {
          if (!result.isConfirmed) return;

          setSubmitLoading(true);
          finalSubmitPracticalAssessment(formData)
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Assessment Submitted successfully!",
                text: '',
                timer: 3000,
                willOpen: () => Swal.showLoading(),
              }).then(() => {
                props.setAssignmentReload(getRandomInt(5000));
                dispatch(setPracAssessmentLaunched(false));
                dispatch(setPracAssessSaved(true));
                setReadOnly(true);
                props.setIsFirstLoad(true);
                props.setAssessmentLoading(true)
              });
            })
            .catch((error) => {
              console.log('error', error);             
              if(error.response.status==400 && error?.response?.data?.alreadySubmitted==true){
                Swal.fire({
                  icon: "info",
                  title: "Assessment already submitted",
                  text: error.response.data.message,
                }).then(()=>{
                  props.initiateReload();
                })
              }else{
                Swal.fire({
                icon: 'error',
                title: 'Assessment failed to submit!',
              });
              }
            })
            .finally(() => {
              setSubmitLoading(false);
            });
        });
      }
    },
  });
  

  useEffect(() => {
    setEpochTime(cDate ? Math.floor(new Date(cDate).getTime() / 1000) : '');
    
    // Update form values only when these dependencies change
    formik.setValues(prev => ({
      ...prev,
      student_Signature: isSig,
      student_Signature_Ip: ipAddress,
      student_Signature_Date: cDate,
      student_signature_type: signatureType
    }),false); // false prevents validation on every update
  }, [cDate, isSig, ipAddress,signatureType]);

  useEffect(()=>{
    if(questionData.question_type == 'Student Declaration'){
      formikData.setFieldValue('is_check_terms_and_conditions', termCondition);
    }
  },[questionData])
  

  useEffect(() => {   
    // Usage
    const updatedData = renameKeys(props.practicalAssessmentData?.header);
    setReadOnlyData(updatedData);
    if (updatedData?.student_signature) {
      setIsSignature(true)
    } else {
      setIsSignature(null)
    }
    setIsSig(updatedData?.student_signature)

    if (isAssessmentReview && updatedData?.student_signature) {
      if (!updatedData?.student_signature) {
        let readOnlyData = {
          student_signature: '#',
          signature_date: null,
          ip: ''
        }
        setReadOnlyData(readOnlyData);
      }
      setIsSignature(null)
      setReadOnly(true)
    }
    
  }, [props.practicalAssessmentData?.header])


  useEffect(() => {
    // console.log("question data is", quiz)
    // console.log('FromREDUX question data is here ', quizData)
    setclear(!clear);
    if (quizData.length) {
      if (question == 0) {
        if (quizData[0]?.question_type == 'Practical Criteria' && !('practicalFiles' in quizData[0])) {
          setquestion(0)
        }else{
          setstarttime(new Date().valueOf());
          if(Array.isArray(quizData[0])){
            setquestion(quizData[0][0].qid);  
          } else { 
            setquestion(quizData[0].qid);
          }
          setquesno(1);
          setquestionData(quizData[0]);
        }       
      }
    }
  },[quiz]);
   
  function convertMinutestoSeconds(minutes) {
    return Math.floor(minutes * 60);
  }


  useEffect(() => {
    // localStorage.removeItem("totaltime");
    var ttimer = setInterval(() => {
      localStorage.setItem(
        "totaltime",
        parseInt(localStorage.getItem("totaltime") || 1) + 1
      );
      setrefresh(!refresh);
    }, 1000);
    return () => {
      clearInterval(ttimer);
      ttimer = null;
      localStorage.removeItem("totaltime");
      for (let i = 0; i < localStorage.length; i++) {
        let value = localStorage.key(i);
        if (value.startsWith("@")) {
          localStorage.removeItem(value);
        }
      }
    };
  }, []);

  const handleQuestion = (id) => {
    const fData = quizData.filter((item) => Array.isArray(item) ? item[0].qid == id : item.qid == id);
    setquestionData(fData[0]);
  };

  const onChange = (oid = []) => {
    dispatch(
      setQuiz([question, { ...questionData, selected: oid, choice: [] }])
    );
    setclear(!clear);
  };

  function fancyTimeFormat(duration) {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    // props.currentTimeStatus(hrs, mins, secs)
    return ret;
  }

  const submitQuiz = () => {
    setquizdesc({ ...quizdesc, duration: 0 });
    // settime(-2);
    handleSubmit();
    // props.setshow({ review: true });
  };

  const handleSubmit = async (section = '') => {
    // settime(-2);
    setLoading(true);
    setSubmitLoading(true);
    var score_individual = [];
    var total_score_individual = [];
    var individual_time = [];
    var individual_time_db = [];
    let quizanswer = [];
    let qid = [];
    props.updateSubmitQuizAssessment();
    for (let i = 0; i < localStorage.length; i++) {
      let value = localStorage.key(i);
      if (value.startsWith("@qa")) {
        // console.log(value);
        // const key = value.substr(1);
        const key = value.replace("@qa","").replace("qa","");
        const score = quizData.filter((data) => Array.isArray(data) ? data.filter(d=> d.qid == key) : data.qid == key);
        let scoreTemp = 0;
        if (score.length > 0) {
          scoreTemp = score[0].score;
        }
        individual_time.push({
          key,
          time: localStorage.getItem(value),
          score: scoreTemp,
        });
      }
    }

    const getAnswerItem = (item) =>{
      // console.log("item.question_type>>>>>>>>>>>>", item.question_type)
      const answerItemList = []
      if (item.question_type == "Multiple Choice Single Answer") {
        if (
          item.selected != "" &&
          item.selected != [] &&
          item.selected != null
        ) {
          answerItemList.push({
            aid: 0,
            oid:item.selected,
            qid: item.qid,
            q_option: item.selected,
            // score: item.score,
            docs: null,
          });
        }
      }
  
      // Multiple Choice Multiple Answer
      if (item.question_type == "Multiple Choice Multiple Answer") {
        // console.log("the code is above here")
        item && item?.selected && item?.selected?.length && item.selected.map((data) => {
          if (
            item.selected != "" &&
            item.selected != [] &&
            item.selected != null
          ) {
            if (data != "" && data != null) {
              answerItemList.push({
                aid: 0,
                oid:data,
                qid: item.qid,
                q_option: data,
                // score: item.score,
                docs: null,
              });
            }
          }
        });
      }
  
      // Match the Column
      if (item.question_type == "Match the Column") {
        // console.log("itemssssss->",item);
        item.choice.map((data, itr) => {
          if (data != "" && data != [] && data != null) {
            answerItemList.push({
              aid: 0,
              oid:item.options[itr].oid,
              qid: item.qid,
              q_option: data,
              // score: item.scoreArr[itr],
              docs: null,
            });
          }
        });
      }
  
      // Short Answer
      if (item.question_type == "Short Answer") {
        if (
          item.selected != "" &&
          item.selected != [] &&
          item.selected != null
        ) {
          answerItemList.push({
            aid: 0,
            qid: item.qid,
            q_option: (Array.isArray(item?.selected) && item?.selected.length) ? item?.selected[0]?.q_option.toString().trim() : item.selected.toString().trim(),
            // score: item.score,
            docs: null,
          });
        }
      }
  
      // Long Answer
      if (item.question_type == "Long Answer") {
        if (
          item.selected != "" &&
          item.selected != [] &&
          item.selected != null
        ) {
          answerItemList.push({
            aid: 0,
            qid: item.qid,
            q_option: (Array.isArray(item?.selected) && item?.selected.length) ? item?.selected[0]?.q_option.toString().trim() : item.selected.toString().trim(),
            // score: item.score,
            docs: null,
          });
        }
      }
  
      // order list
      if (item.question_type == "Order List") {
        item.choice.map((data, ind)=>{
          if (data !== "" && (data.length!=0) && data !== null) {
            answerItemList.push({
              aid: 0,
              oid:item.selected[ind],
              qid: item.qid,
              q_option: `${data.replace("___","")}___${item?.selectedItems[ind]}`,
              // score: item.scoreArr[ind],
              docs: null,
            });
          }
        })
      }
  
      // Fill In The Blank
      if (item.question_type == "Fill in the blank") {
        // console.log("itemssssss->",item);
        item.selected.map((data)=>{
          if (data != "" && data != [] && data != null) {
            answerItemList.push({
              aid: 0,
              oid:data.oid,
              qid: item.qid,
              q_option: JSON.stringify([data.q_option_match.trim()]),
              // score: data.score,
              docs: null,
            });
          }
        })
      }
  
      // Choice Matrix
      if (item.question_type == "Choice Matrix") {
        // console.log("itemssssss->",item);
        item.selected.map((data)=>{
          if (data != "" && data != [] && data != null) {
            answerItemList.push({
              aid: 0,
              oid:data.oid,
              qid: item.qid,
              q_option: `${JSON.stringify(data.q_option).replace('___','')}___${JSON.stringify([data.q_option_match])}`,
              // score: data.score,
              docs: null,
            });
          }
        })
      }
  
      // Document Upload
      if (item.question_type === "Document Upload") {
        // console.log("document upload item->",item);
        if(item?.selected){
          item.selected.map((d)=>{
            answerItemList.push({
              aid: 0,
              qid: item.qid,
              docs: d,
            });
          })
        }
      }
      return answerItemList;
    }

    quizData.map((item) => {
      // total_score_individual.push(item.correct_score)
      // Multiple Choice Single Answer
      if(item.question_type){
        quizanswer = [...quizanswer, ...getAnswerItem(item)]
        // quizanswer.push(...getAnswerItem(item))
        qid.push(item.qid);
      }else if(Array.isArray(item) && item.length){
        item.map((subItem)=>{
          // total_score_individual.push(subItem.correct_score)
          // quizanswer.push(...getAnswerItem(subItem));
          quizanswer = [...quizanswer, ...getAnswerItem(subItem)]
          qid.push(subItem.qid);
        })
      }
    });

    individual_time.sort(function (a, b) {
      return a.key - b.key;
    });

    individual_time.map((item) => {
      // qid.push(item.key);
      // score_individual.push(item.score);
      individual_time_db.push(item.time);
    });

    qid.map(q=>{
      quizData.map(qd=>{
        if(Number(q) === qd?.qid){
          total_score_individual.push(qd.correct_score)
          score_individual.push(qd.score)
        } else if(Array.isArray(qd)){
            qd.map(subQd=>{
            if(Number(q) === subQd.qid){
              total_score_individual.push(subQd.correct_score)
              score_individual.push(subQd.score)
            }
          })
        }
      })
    })

    // const percentage = individual_time.reduce(
    //   (acc, item, index) => acc + item.score,
    //   0
    // );
    const percentage = score_individual.reduce((acc, item, ind)=>acc+item,0);
    
    const maxMarks = quizData.reduce((acc, item, index) => Array.isArray(item) ? acc + item.reduce((a,v,i)=>a + v.correct_score, 0) : acc + item.correct_score, 0);

    if (section == 'unanswerStatus') {
      let setFinalStr = false
      // for (var i = 0; i < qid.length; i++) {
      //   let isAnswered = false
      //   for (var j = 0; j < quizanswer.length; j++) {
      //     if (qid[i] == quizanswer[j].qid) {
      //       isAnswered = true;
      //       break;
      //     }
      //   }
      //   if (!isAnswered) {
      //     setFinalStr = false
      //     break;
      //   }
      //   else {
      //     setFinalStr = true
      //   }
      // }
      const answeredQues = quizanswer.map((ans)=>(ans.qid));
      const uniqueAnseweredQues = [...new Set(answeredQues)]
      if(uniqueAnseweredQues.length===qid.length){
        setFinalStr = true;
      } 
      setToFinal(setFinalStr ? '' : '<p><span style="color:#e74c3c">You have unanswered questions</span></p>')
      return
    }
    // let score_individualsss = {}
    // quizanswer.map(item => {
    //   if (score_individualsss[item.qid]) {
    //     score_individualsss[item.qid] = score_individualsss[item.qid] + item.score
    //   } else {
    //     score_individualsss[item.qid] = item.score
    //   }
    // })

    // check hybrid question
    let isHybrid = false;
    const hybridTypeQues = ["Long Answer", "Document Upload"];
    for (let q = 0; q < quizData.length; q++) {
      const question = Array.isArray(quizData[q]) ? quizData[q] : [quizData[q]];
      for (let inQ = 0; inQ < question.length; inQ++) {
        if (hybridTypeQues.includes(question[inQ].question_type)) {
          isHybrid = true;
          break;
        }
      }
      if (isHybrid) {
        break;
      }
    }
    props.setIsExamLaunchedToFalse();
    const body = {
      quid: props.quizId,
      totaltime:
        parseInt(localStorage.getItem("totaltime")) + duration_consumed ? parseInt(localStorage.getItem("totaltime")) + duration_consumed : 0,
      // score_individual: score_individual.join(),
      // total_score_individual: total_score_individual.join(),
      individual_time_db: individual_time_db.join(),
      // score: percentage,
      // percentage: ((percentage / maxMarks) * 100).toFixed(4),
      // result_status:
      //   (percentage / maxMarks) * 100 >= Number(props?.assPassMark)
      //     ? "pass"
      //     : "fail",
      user_type: "student",
      starttime: starttime,
      endtime: new Date().valueOf(),
      publicip: await PublicIP.v4(),
      quizanswer,
      r_qids: qid.join(),
      assessment_id: props.aid,
      // total_score:maxMarks,
      hybrid: isHybrid ? 1 : 0,
    };
    AssessmentSetToFinalService(body)
      .then((res) => {
        Swal.close()
        props.stop(true)
        if (res.data.status) {
          localStorage.removeItem("totaltime");
          for (let i = 0; i < localStorage.length; i++) {
            let value = localStorage.key(i);
            if (value.startsWith("@")) {
              localStorage.removeItem(value);
            }
          }
          for (let i = 0; i < localStorage.length; i++) {
            let value = localStorage.key(i);
            if (value.startsWith("@")) {
              localStorage.removeItem(value);
            }
          }
          localStorage.removeItem("totaltime");
          props.setrId(res.data.rid);
          setLoading(false);
          props.setshow({ review: true });          
          props.initiateReload()
          props.getSubContentMethod()
        }
      })
      .catch((err) => {
        Swal.close();
        console.log(err);
        if(err.response.status==400 && err?.response?.data?.alreadySubmitted==true){
          Swal.fire({
            icon: "info",
            title: "Assessment already submitted",
            text: err.response.data.message,
          }).then(()=>{
            props.initiateReload();
          })
        }
      }).finally(()=>{
        setSubmitLoading(false);
      });
  };
  useEffect(()=>{
    if (props.assessmentdetails.length) {
      if (props?.submitQuizAssessment) {
        // console.log("___________________ view ques 1")
        Swal.fire({
          icon: "success",
          title: "Quiz Submitted",
        });
        handleSubmit('');
      }
    }
  }, [props?.submitQuizAssessment])

  const handleSaveAndNext = () => {
    let quizanswer = [];
    let timearr = [];
    // console.log(quizanswer,"_________ yaha")

    const getAnswerItem = (item) =>{
      // console.log("item.question_type>>>>>>>>>>>>", item.question_type)
      const answerItemList = []
      // Multiple Choice Multiple Answer && Multiple Choice Single Answer && Choice Matrix && Fill In The Blank && Document Upload
      if (item.question_type == "Multiple Choice Single Answer" || item.question_type == "Multiple Choice Multiple Answer" || item.question_type == "Choice Matrix" || item.question_type == "Fill in the blank" || item.question_type === "Document Upload") {
        answerItemList.push({
          aid: 0,
          qid: item.qid,
          q_option: item.selected,
          // score: item.score,
          docs: null,
          type: item.question_type,
        });
      }
  
      // Match the Column && Order List
      if (item.question_type == "Match the Column" || item.question_type == "Order List") {
        answerItemList.push({
          aid: 0,
          qid: item.qid,
          q_option: {choice: item.choice, selectedItems: item.selectedItems, selected: item.selected},
          // score: item.score,
          docs: null,
          type: item.question_type,
        });
      } 
  
      // Long Answer && Short Answer
      if (item.question_type == "Long Answer" || item.question_type == "Short Answer") {
        answerItemList.push({
          aid: 0,
          qid: item.qid,
          q_option: (Array.isArray(item?.selected) && item?.selected.length) ? item?.selected[0]?.q_option.toString().trim() : item.selected.toString().trim(),
          // score: item.score,
          docs: null,
          type: item.question_type,
        });
      }
  
      return answerItemList;
    }
    quizData.map((item) => {
      if(item.question_type){
        quizanswer = [...quizanswer, ...getAnswerItem(item)]
        timearr.push(localStorage.getItem(`@qa${item?.qid}qa`));
      }else if(Array.isArray(item) && item.length){
        item.map((subItem)=>{
          quizanswer = [...quizanswer, ...getAnswerItem(subItem)]
          timearr.push(localStorage.getItem(`@qa${subItem?.qid}qa`));
        })
      }
    });
    if(props.assessmentType != '13' && props.assessmentType != '14'){
      let body = {
        assessment_id: Number(props.aid),
        attempt_no,
        quizanswer,
        quid: props.quizId,
        duration: time,
        individual_time: timearr.join(),
        duration_consumed:
          parseInt(localStorage.getItem("totaltime")) + duration_consumed,
      };
      SubmitSingleAnswersService(body)
      .then((res) => { })
      .catch((error) => {
        if(error.response.status==400 && error?.response?.data?.alreadySubmitted==true){
          Swal.fire({
            icon: "info",
            title: "Assessment already submitted",
            text: error.response.data.message,
          }).then(()=>{
            props.initiateReload();
          })
        }
      });
    }   
  };

  const AutoSubmitModal = () => {
    // if (time == -1) {
    //   // setshowmodal(true);
    //   Swal.fire({
    //     icon: "success",
    //     title: "Quiz Submitted",
    //   })
    //     .then((res) => {
    //       submitQuiz();
    //     })
    //     .catch(() => {
    //       submitQuiz();
    //     });
    // }
    return (
      <div className="card card-header-transparent card-header-transparent-no-padding mb-3">
        {/* <div className="card-header">{quizdesc && quizdesc.quiz_name}</div> */}
        <div className="card-body">
          <div className="card card-quiz card-header-dark">
            <div className="card-header">Submit Quiz</div>
            <div className="card-body">
              <div className="form-group">
                <label className="d-block mb-3">
                  <strong>Are you sure you want to submit this quiz?</strong>
                </label>
              </div>
            </div>
          </div>

          <div className="quiz-footer-btns mt-4">
            {/* <a
              onClick={handleSubmit}
              className="btn btn-white-bordered btn-primary"
            > */}
            <button
              onClick={()=>{handleSubmit()}}
              className="btn btn-white-bordered btn-primary"
              disabled={loading}
            >
              {loading ? (
                <i className="fas fa-cog fa-spin"></i>
              ) : (
                <i className="fal fa-check"></i>
              )}
              Submit Quiz
            </button>
            {/* </a> */}

            <button
              onClick={() => setshowmodal(false)}
              className="btn btn-danger"
              disabled={loading}
            >
              <i className="fal fa-times"></i>Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  
  if (time == 0 && props?.submitQuizAssessment) {
    if (quizdesc.description != 0) {
      clearInterval(interval);
    }
    Swal.fire({
      icon: "success",
      title: "Quiz Submitted",
    }).then(() => {
      submitQuiz();
    });
  }

  const isShowing = (index) => {
    if (quizData.length <= 10) {
      return ''
    }
    else if (quesno > 4 && quesno < quizData.length - 5) {
      if (inRange(index, quesno - 4, quesno + 5)) {
        return ''
      }
      else {
        return 'd-none'
      }
    }
    else if (quesno <= 4) {
      if (inRange(index, 0, 9)) {
        return
      }
      else {
        return 'd-none'
      }
    }
    else if (quesno >= quizData.length - 5) {
      if (inRange(index, quizData.length - 10, quizData.length)) {
        return
      }
      else {
        return 'd-none'
      }
    }
  }

  function inRange(x, min, max) {
    return ((x - min) * (x - max) <= 0);
  }

  const handleReviewNavQuestion = (nextNav) => {
    if (nextNav == 'next' && quesno != (quizData.length)) {
      setquesno(quesno + 1);
      setquestion(Array.isArray(quizData[quesno]) ? quizData[quesno][0].qid : quizData[quesno].qid);
      handleQuestion(Array.isArray(quizData[quesno]) ? quizData[quesno][0].qid : quizData[quesno].qid);
    }
    else if (nextNav == 'back' && quesno != 1) {
      setquesno(quesno - 1)
      setquestion(Array.isArray(quizData[quesno - 2]) ? quizData[quesno - 2][0].qid : quizData[quesno - 2].qid);
      handleQuestion(Array.isArray(quizData[quesno - 2]) ? quizData[quesno - 2][0].qid : quizData[quesno - 2].qid);
    }
    else if (nextNav == 'last' && quesno != (quizData.length)) {
      setquesno(quizData.length)
      setquestion(Array.isArray(quizData[quizData.length - 1]) ? quizData[quizData.length - 1][0].qid : quizData[quizData.length - 1].qid);
      handleQuestion(Array.isArray(quizData[quizData.length - 1]) ? quizData[quizData.length - 1][0].qid : quizData[quizData.length - 1].qid);
    }
    else if (nextNav == 'first' && quesno != 1) {
      setquesno(1)
      setquestion(Array.isArray(quizData[0]) ? quizData[0][0].qid : quizData[0].qid);
      handleQuestion(Array.isArray(quizData[0]) ? quizData[0][0].qid : quizData[0].qid);
    }
  }

  const handleNav = (nextNav) => {
    if (nextNav == 'next' && quesno != (quizData.length)) {
      setquesno(quesno + 1);
      localStorage.setItem(
        Array.isArray(quizData[quesno]) ? `@qa${quizData[quesno][0].qid}qa` : `@qa${quizData[quesno].qid}qa`,
        parseInt(localStorage.getItem(Array.isArray(quizData[quesno]) ? `@qa${quizData[quesno][0].qid}qa` : `@qa${quizData[quesno].qid}qa`) || 0) + 1
      );
      setquestion(Array.isArray(quizData[quesno]) ? quizData[quesno][0].qid : quizData[quesno].qid);
      handleQuestion(Array.isArray(quizData[quesno]) ? quizData[quesno][0].qid : quizData[quesno].qid);
      handleSaveAndNext();
    }
    else if (nextNav == 'back' && quesno != 1) {
      // console.log('the ques and quizData is ', quesno, quizData, quizData.length)
      setquesno(quesno - 1)
      localStorage.setItem(
        Array.isArray(quizData[quesno - 2]) ? `@qa${quizData[quesno - 2][0].qid}qa` : `@qa${quizData[quesno - 2].qid}qa`,
        parseInt(localStorage.getItem(Array.isArray(quizData[quesno - 2]) ? `@qa${quizData[quesno - 2][0].qid}qa` : `@qa${quizData[quesno - 2].qid}qa`) || 0) + 1
      );
      setquestion(Array.isArray(quizData[quesno - 2]) ? quizData[quesno - 2][0].qid : quizData[quesno - 2].qid);
      handleQuestion(Array.isArray(quizData[quesno - 2]) ? quizData[quesno - 2][0].qid : quizData[quesno - 2].qid);
      handleSaveAndNext();
    }
    else if (nextNav == 'last' && quesno != (quizData.length)) {
      setquesno(quizData.length)
      localStorage.setItem(
        Array.isArray(quizData[quizData.length - 1]) ? `@qa${quizData[quizData.length - 1][0].qid}qa` : `@qa${quizData[quizData.length - 1].qid}qa`,
        parseInt(localStorage.getItem(Array.isArray(quizData[quizData.length - 1]) ? `@qa${quizData[quizData.length - 1][0].qid}qa` : `@qa${quizData[quizData.length - 1].qid}qa`) || 0) + 1
      );
      setquestion(Array.isArray(quizData[quizData.length - 1]) ? quizData[quizData.length - 1][0].qid : quizData[quizData.length - 1].qid);
      handleQuestion(Array.isArray(quizData[quizData.length - 1]) ? quizData[quizData.length - 1][0].qid : quizData[quizData.length - 1].qid);
      handleSaveAndNext();
    }
    else if (nextNav == 'first' && quesno != 1) {
      setquesno(1)
      localStorage.setItem(
        Array.isArray(quizData[0]) ? `@qa${quizData[0][0].qid}qa` : `@qa${quizData[0].qid}qa`,
        parseInt(localStorage.getItem(Array.isArray(quizData[0]) ? `@qa${quizData[0][0].qid}qa` : `@qa${quizData[0].qid}qa`) || 0) + 1
      );
      setquestion(Array.isArray(quizData[0]) ? quizData[0][0].qid : quizData[0].qid);
      handleQuestion(Array.isArray(quizData[0]) ? quizData[0][0].qid : quizData[0].qid);
      handleSaveAndNext();
    }
  }

  const setToFinal = (str) => {
    Swal.close();
    Swal.fire({
      title: 'Are you sure?',
      // text: Str.setFinalPopup,
      html: `${Str.setFinalPopup} ${str}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: Str.Submit,

    }).then(function (result) {
      if (result.value) {
        handleSubmit('')
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "",
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        })
        // Swal.close()

      }else{
        setSubmitLoading(false);
      }
    });
  }

  const renderQuesType = (type) => {
    switch (type) {
      case "Multiple Choice Single Answer":
        return <span className="ques__type">Single Choice <i className="fal fa-tasks"></i></span>;
      case "Multiple Choice Multiple Answer":
        return <span className="ques__type">Multiple Choice <i className="far fa-check-square"></i></span>;
      case "Match the Column":
        return <span className="ques__type">Match The Column <i className="far fa-line-columns"></i></span>;
      case "Short Answer":
        return <span className="ques__type">Short Answer <i className="far fa-comment-alt-lines"></i></span>;
      case "Long Answer":
        return <span className="ques__type">Long Answer <i className="fal fa-align-left"></i></span>;
      case "Fill in the blank":
        return <span className="ques__type">Fill In The Blanks <i className="far fa-keyboard"></i></span>;
      case "Order List":
        return <span className="ques__type">Order List <i className="fas fa-sort-numeric-down"></i></span>;
      case "Choice Matrix":
        return <span className="ques__type">Choice Matrix <i className="fal fa-table"></i></span>;
      case "Document Upload":
        return <span className="ques__type">Document Upload <i className="fal fa-file-upload"></i></span>;
      case "Practical Criteria":
        return <span className="ques__type">Practical Criteria <i className="fal fa-ballot-check"></i></span>;
      case "Student Declaration":
        return <span className="ques__type">Student Declaration <i className="fal fa-signature"></i></span>;
      default:
        return
    }
  }

  const checkLocalstorageKeys = (item) => {
    let isZero = 0;
    if (Array.isArray(item)) {
      for (let i = 0; i < item.length; i++) {
        if (!localStorage.getItem(`@qa${item[i]?.qid}qa`) || localStorage.getItem(`@qa${item[i]?.qid}qa`)==null || localStorage.getItem(`@qa${item[i]?.qid}qa`) == 0) {
          isZero = 0;
        } else {
          isZero = 1;
          break;
        }
      }
    } else {
      isZero = (!localStorage.getItem(`@qa${item?.qid}qa`) || localStorage.getItem(`@qa${item?.qid}qa`)==null || localStorage.getItem(`@qa${item?.qid}qa`) == 0) ? 0 : 1;
    }
    return isZero;
  }

  const isSelectedAnswer = (item) => {
    let isSelected = false;
    if (item?.question_type == "Practical Criteria") {
      const filteredQuizData = quizData?.filter(quiz => quiz?.qid === item?.qid);
      if (filteredQuizData?.some(quiz => quiz?.practicalFiles?.length > 0 || quiz?.lecturer_observation_toggle == 1)) {
        isSelected = true;
      }
      return isSelected;
    };

    if (item?.question_type == "Student Declaration") {
      const filteredQuizData = quizData?.filter(quiz => quiz?.qid == "Student Declaration");
      if (filteredQuizData?.some(quiz =>quiz?.is_check_terms_and_conditions == 1)
      ) {
        isSelected = true;
      }
      return isSelected;
    }
    

    if (Array.isArray(item)) {
      for (let i = 0; i < item.length; i++) {
        // console.log("___________________ selected ______", item[i].selected);
        if (item[i]?.selected?.length > 0 && item[i]?.selected !== "" && item[i].selected !== undefined && item[i].selected !== null) {
          isSelected = true;
          break;
        } else {
          isSelected = false;
        }
      }
    } else {
      isSelected = (item?.selected?.length > 0 && item?.selected !== "" && item?.selected !== undefined && item?.selected !== null) ? true : false;
    }
    return isSelected;
  }
 
  const handleAssessmentSubmit = () => {
    Swal.fire({
      icon: "success",
      title: "Assessment saved successfully!",
      showConfirmButton: true,
    });
    props.initiateReload();
    props.setAssignmentReload(getRandomInt(5000));
    dispatch(setPracAssessmentLaunched(false));    
    dispatch(setPracAssessSaved(true));   
    setSubmitLoading(false);
    props.stop(true);
  };
 

  return (
    <div class="card card-header-transparent">
      {/* {!showmodal && time != 0 ? ( */}
        <>
          {/* <div class="card-header ">{quizdesc && quizdesc.quiz_name}</div> */}
          <div class="">
            <div class="row align-items-center d-none">
              <div class="col-md-4">
                {unlimited ? (
                  <></>
                ) : (
                  <div class="quicz-time">
                    Time Left: <strong>{fancyTimeFormat(time)}</strong>
                  </div>
                )}
              </div>
              <div class="col-md-8 text-right">
                {/* <div class="quiz-labels">
                  <label class="label label-quiz-red">
                    <span class="label-square-icon"></span>Unanswered
                  </label>
                  <label class="label label-quiz-green">
                    <span class="label-square-icon"></span>Answered
                  </label>
                  <label class="label label-quiz-orange">
                    <span class="label-square-icon"></span>Not-visited
                  </label>
                </div> */}

              </div>
            </div>
            {/* Question Group */}
            {Object.keys(questionData).length ? Array.isArray(questionData) ? (
              <div class="card card-quiz card-header-dark">
                <div class="card-header">{questionData[0]?.group_name}</div>
                <div class="card-body">
                  {questionData.map((q,i)=>(
                    <div key={i} className={(questionData.length -1) === i ? "" : "mb-4"}>
                    <DisplayQuestion
                      grpId={questionData[0].qid}
                      qInd={i}
                      question={q}
                      setquestion={setquestionData}
                      refresh={refresh}
                      setrefresh={setrefresh}
                      // quesno={quesno}
                      clear={clear}
                      showQuesType={renderQuesType(q?.question_type)}
                    />
                    {i !== questionData.length -1 ? <hr/> : null}
                    </div>
                  ))}
                </div>
              </div>
            ) :
              (<div class="card card-quiz card-header-dark">
                <div class="card-header">{questionData?.question_type == "Student Declaration" ? questionData?.question_type : `Question ${quesno}`} {renderQuesType(questionData?.question_type)}</div>
                <div class="card-body">
                  <DisplayQuestion
                  // grpId={questionData?.qid}
                    question={questionData}
                    setquestion={setquestionData}
                    refresh={refresh}
                    setrefresh={setrefresh}
                    // quesno={quesno}
                    clear={clear}
                    files={props.files}
                    setFiles={setFiles}
                    setPhotoFiles={setPhotoFiles}
                    setVideoFiles={setVideoFiles}
                    setDocFiles={setDocFiles}
                    photoFiles={photoFiles}
                    videoFiles={videoFiles}
                    docFiles={docFiles}
                    assessmentdetails={props.assessmentdetails}
                    assessementQuestionList={props.assessementQuestionList}
                    setLecturerObservations={setLecturerObservations}
                    lecturerObservations={lecturerObservations}
                    formik={formik}
                    formikData={formikData}                    setCDate={setCDate}
                    cDate={cDate}
                    signaturePadRef={signaturePadRef}
                    fileInputRef={fileInputRef}
                    setIsSig={setIsSig}
                    isSig={isSig}
                    setIpAddress={setIpAddress}
                    ipAddress={ipAddress}
                    readOnly={readOnly}
                    readOnlyData={readOnlyData}
                    practicalAssessmentData={props.practicalAssessmentData}
                    isSignature={isSignature}
                    setIsSignature={setIsSignature}
                    setSignatureType={setSignatureType}
                    signatureType={signatureType}
                    setIsSignatureSave={setIsSignatureSave}
                    isSignatureSave={isSignatureSave}
                    termCondition={termCondition}
                    setTermCondition={setTermCondition}
                  />
                </div>
              </div>)
              : null
            }

          {((isAssessmentReview)) ?
            <div className="pagination-btn-group d-flex justify-content-between mt-4 mb-4">
              <ul class="quiz-pagination d-flex">
                <li
                  class={"page-item action-pagination " + (quesno != 1 ? '' : 'disabled')}
                  onClick={() => { handleReviewNavQuestion('first') }}
                >
                  <a class={"page-link pre-page-link "}>
                    <i class="fas fa-angle-double-left"></i>
                  </a>
                </li>
                <li
                  onClick={() => { handleReviewNavQuestion('back') }}
                  class={"page-item action-pagination pre-step-link " + (quesno != 1 ? '' : 'disabled')}>
                  <a class="page-link" aria-label="Previous">
                    <i class="fas fa-chevron-left"></i>
                    <span class="sr-only"><i class="fas fa-angle-double-left"></i></span>
                  </a>
                </li>

                {quizData.map((item, index) => {
                  var cls = "btn btn-white-bordered review-question-item"
                  cls = cls + ` ${quesno - 1 == index ? 'active' : ''} ${isShowing(index)}`
                  return (
                    <li
                      key={index}
                      class={cls}
                      onClick={() => {
                        setquesno(index + 1);
                        setquestion(Array.isArray(item) ? item[0].qid : item.qid);
                        handleQuestion(Array.isArray(item) ? item[0].qid : item.qid);
                      }}>
                      <a class="page-link">{index + 1}</a>
                    </li>
                  );
                })}
                <li
                  class={"page-item action-pagination next-step-link" + (quesno == quizData.length ? ' disabled' : '')}
                  onClick={() => { handleNav('next') }}
                >
                  <a class="page-link " aria-label="Next">
                    <i class="fas fa-chevron-right"></i>
                    <span class="sr-only">Next</span>
                  </a>
                </li>
                <li
                  class={"page-item action-pagination" + (quesno == quizData.length ? ' disabled' : '')}
                  onClick={() => { handleNav('last') }}
                >
                  <a class="page-link next-page-link"><i class={"fas fa-angle-double-right"}></i></a>
                </li>
              </ul>

              <div class="quiz-footer-btns ">
                {quizData.length != quesno ? (
                  <a
                    onClick={() => {
                      const val = keys.indexOf(`qa${question.toString()}qa`);
                      setquestion(keys[val + 1]?.replaceAll("qa", ""));
                      setquesno(val + 2);
                      handleQuestion(keys[val + 1]?.replaceAll("qa", ""));
                    }}
                    class="btn btn-white-bordered btn-primary"
                  >
                    {/* <i class="fal fa-angle-right"></i> */}
                    View Next
                  </a>
                ) : null}
              </div>
            </div>
            : <>
              <ul className="quiz-info">
                <li className="Unanswered-quiz">Unanswered</li>
                <li className="answered-quiz">Answered</li>
                <li className="non-visited">Not-visited</li>
              </ul>
              <div className="pagination-btn-group d-flex justify-content-between mt-4 mb-4">
                <ul class="quiz-pagination d-flex">

                  <li
                    class={"page-item action-pagination " + (quesno != 1 ? '' : 'disabled')}
                    onClick={() => { handleNav('first') }}
                  >

                    <a class={"page-link pre-page-link "}>
                      <i class="fas fa-angle-double-left"></i>
                    </a>
                  </li>
                  <li
                    onClick={() => { handleNav('back') }}
                    class={"page-item action-pagination pre-step-link " + (quesno != 1 ? '' : 'disabled')}>
                    <a class="page-link" aria-label="Previous">
                      <i class="fas fa-chevron-left"></i>
                      <span class="sr-only"><i class="fas fa-angle-double-left"></i></span>
                    </a>
                  </li>

                  {quizData.map((item, index) => {
                    var cls = "btn btn-white-bordered ";
                    if (isSelectedAnswer(item)) {
                      cls += "btn-white-bordered-green";
                    } else if (
                      // parseInt(localStorage.getItem((Array.isArray(item) ? `@${item.map(i=>i.qid).join()}` : `@${item.qid}`))) == 0
                      checkLocalstorageKeys(item) === 0 && index !== 0
                    ) {
                      cls += "btn-white-bordered-orange";
                    } else if (
                      // (Array.isArray(item) ? item.map(i=>i.selected).join('') : item.selected) == "" &&
                      // parseInt(localStorage.getItem((Array.isArray(item) ? `@${item.map(i=>i.qid).join()}` : `@${item.qid}`))) != 0
                      index === 0 || (!isSelectedAnswer(item) && checkLocalstorageKeys(item) !== 0)
                    ) {
                      cls += "btn-white-bordered-red";
                    }
                    cls = cls + ` ${quesno - 1 == index ? 'active' : ''} ${isShowing(index)}`
                    return (
                      // <a
                      //   onClick={() => {
                      //     setquesno(index + 1);
                      //     localStorage.setItem(
                      //       "@" + item.qid,
                      //       parseInt(localStorage.getItem("@" + item.qid) || 0) + 1
                      //     );
                      //     setquestion(item.qid);
                      //     handleQuestion(item.qid);
                      //     handleSaveAndNext();
                      //   }}
                      //   class={cls}
                      // >
                      //   {index + 1}
                      // </a>
                      <li
                        key={index}
                        class={cls}
                        onClick={() => {
                          setquesno(index + 1);
                          localStorage.setItem(
                            `@qa${Array.isArray(item) ? item[0].qid : item.qid}qa`,
                            parseInt(localStorage.getItem(`@qa${(Array.isArray(item) ? item[0].qid : item.qid)}qa`) || 0) + 1
                          );
                          setquestion(Array.isArray(item) ? item[0].qid : item.qid);
                          handleQuestion(Array.isArray(item) ? item[0].qid : item.qid);
                          handleSaveAndNext();
                        }}>
                        <a class="page-link">{index + 1}</a>
                      </li>
                    );
                  })}
                  <li
                    class={"page-item action-pagination next-step-link" + (quesno == quizData.length ? ' disabled' : '')}
                    onClick={() => { handleNav('next') }}
                  >
                    <a class="page-link " aria-label="Next">
                      <i class="fas fa-chevron-right"></i>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                  <li
                    class={"page-item action-pagination" + (quesno == quizData.length ? ' disabled' : '')}
                    onClick={() => { handleNav('last') }}
                  >
                    <a class="page-link next-page-link"><i class={"fas fa-angle-double-right"}></i></a>
                  </li>
                </ul>

                <div class="quiz-footer-btns ">
                  {/* <a onClick={() => onChange()} class="btn btn-danger">
                  <i class="fal fa-redo-alt fa-flip-horizontal"></i>Clear
                </a>
                {quizData[0]?.qid != question && (
                  <a
                    onClick={() => {
                      const val = keys.indexOf(question.toString());
                      setquestion(keys[val - 1]);
                      setquesno(val);
                      handleQuestion(keys[val - 1]);
                      handleSaveAndNext();
                    }}
                    class="btn btn-white-bordered btn-secondary"
                  >
                    <i class="fal fa-angle-left"></i>Back
                  </a>
                )} */}

                  {quizData.length != quesno ? (
                    <a
                      onClick={() => {
                        const val = keys.indexOf(`qa${question.toString()}qa`);
                        localStorage.setItem(
                          `@${keys[val + 1]}`,
                          parseInt(localStorage.getItem(`@${keys[val + 1]}`) || 0) +
                          1
                        );
                        setquestion(keys[val + 1]?.replaceAll("qa", ""));
                        setquesno(val + 2);
                        handleQuestion(keys[val + 1]?.replaceAll("qa", ""));
                        handleSaveAndNext();
                      }}
                      class="btn btn-white-bordered btn-primary"
                    >
                      {/* <i class="fal fa-angle-right"></i> */}
                      {(props.assessmentdetails?.og_ass_type == 13 || props.assessmentdetails?.og_ass_type == 14) ? 'Save and Next' : 'Next'}
                    </a>
                  ) : (
                      <>
                        {(props.assessmentdetails?.og_ass_type == 13 || props.assessmentdetails?.og_ass_type == 14) ? <>
                          {quizData[quizData?.length-1]?.question_type == "Student Declaration" ? <> {props.assessmentdetails?.status !== "Draft" && <button className="btn btn-primary btn-white-bordered" type="button" 
                            // disabled={!props?.assessmentdetails?.is_Allow_Student && !props?.assessmentdetails?.isStudentAssest}
                           onClick={() => {
                            formikData.setFieldValue("is_draft",'1').then(() => {
                              formikData.handleSubmit()
                            }); 
                          }}>
                            Save Draft
                          </button>}
                          <button
                            type="button"
                            onClick={() => { 
                              formikData.setFieldValue("is_draft",0).then(() => {
                              formikData.handleSubmit()
                              }); 
                            }}
                            // onClick={() => formik.handleSubmit()}
                            className={`btn btn-primary check-btn`}
                            disabled={(props.practicalAssessmentData?.header?.is_Student_Signature_Required && (isSig == '' || isSig == null))}
                            // disabled={props.practicalAssessmentData?.header?.is_Student_Signature_Required && (isSig == '' || isSig == null) || !props?.assessmentdetails?.is_Allow_Student && !props?.assessmentdetails?.isStudentAssest}
                          >
                            <i className={submitLoading == true ? "fas fa-cog fa-spin" : "fas fa-check"}></i>
                            Set to Final
                            </button></> : <button onClick={() => handleAssessmentSubmit()} className={`{btn btn-primary btn-white-bordered`} disabled={submitLoading == true}>Save</button>}
                        </> : <button
                          onClick={() => {
                            setshowmodal(true);
                            handleSubmit('unanswerStatus')
                          }}
                          className={`btn btn-primary check-btn`}
                          disabled={submitLoading == true}>
                            <>
                            <i className={submitLoading == true ? "fas fa-cog fa-spin" : "fas fa-check"}></i>
                            Set to Final
                          </>
                        </button>}
                      </>                  
                  )}
                </div>
              </div>
            </>
          }
          </div>
        </>
      {/* ) : (
        <AutoSubmitModal />
      )} */}
    </div>
  );
}

export default React.memo(ViewQuestion)