import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import Countdown, { zeroPad } from "react-countdown";
import AssignmentStatus from "../AssignmentStatus";
import AssignmentType from "../AssignmentType";
import moment from "moment-timezone";
import { SystemIcons } from "../../common/SystemIcons";
import { RenderAttachList } from "../../common/RenderAttachList";
import AssessmentDetailTab from "./AssessmentDetailTab";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { BASE_URL } from "../../../utils/Constants";
import AssignmentExamStatus from "../AssignmentExamStatus";
import { getPracticalAssessmentQuizQuestionsReview } from "../../../services/QuizService";
import { clearQuiz, setQuizComplete } from "../../../store/actions";
import { useDispatch } from "react-redux";
// moment.tz.setDefault("Africa/Johannesburg");

const ShowResult = ({ data }) => (
  data.grade == 1 ? (
    data.assessmentMarkPercentage == 0 ?
      (<div className='assessments-result-box'><i className="fas fa-circle red"></i>Incomplete</div>) :
      data.assessmentMarkPercentage >= 50 ?
        (<div className='assessments-result-box'><i className="fas fa-circle green"></i>Competent</div>) :
        (<div className='assessments-result-box'><i className="fas fa-circle yellow" ></i>Not yet Competent</div>)

  ) : (<div class="ass-status status-dark-red">
    <i class="fas fa-circle"></i>Pending
  </div>)
);


const Step6 = (props) => {
  const [showFile, setShowFile] = useState(true);
  const [showFeedback, setShowFeedback] = useState(true);
  const [assessementQuestionList, setAssessementQuestionList]=useState([])
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const isWithHeldStatus = localStorage.getItem("resultWithHeld");
  let countTimer = Date.now() + (Date.parse(props.data.marking_deadline_date) - Date.now());
  if (Date.now() > countTimer) {
    countTimer = 0;
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      // setAssignmentReload(getRandomInt(9));
      return <span>
        <ul>
          <li>
            <span id="days">{zeroPad(0)}</span>days
          </li>
          <li>
            <span id="hours">{zeroPad(0)}</span>Hours
          </li>
          <li>
            <span id="minutes">{zeroPad(0)}</span>Minutes
          </li>
        </ul>
      </span>;
    } else {
      //Render a countdown
      return <span>
        <ul>
          <li>
            <span id="days">{zeroPad(days)}</span>days
          </li>
          <li>
            <span id="hours">{zeroPad(hours)}</span>Hours
          </li>
          <li>
            <span id="minutes">{zeroPad(minutes)}</span>Minutes
          </li>
        </ul>
      </span>;
    }
  };

  const handleDownload = ()=>{
    const absolutePath = props?.data.assFile.includes("https") ? props?.data.assFile : BASE_URL + "/" + props?.data.assFile.replaceAll("/home/myaie/public_html/","").replace("public/","").replace("null/","");
    const fileName =  props?.data.assFileName;
    const path_size = props?.data.path_size ? props?.data.path_size : null;

    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to download the assessment files?",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'I am sure',
      html: `Do you want to download assessment files? <br/><br/><b>File Size: ${path_size}</b>`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await props?.downloadFile(absolutePath, fileName);
      }
    })

  }
  const calculateTimeLeft = (close_time) => {
    const now = new Date().getTime();
    const closeTime = new Date(close_time).getTime();
    const timeLeft = closeTime - now;
    console.log("timeLeft:", timeLeft);

    if (timeLeft <= 0) {
      return null;
    }

    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    console.log("time:", `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

   return (
    <>
      <div class="tab-pane fade show active" id="Assessment-Detail">
        <div class="test-series-main-left-blk">
          <div class="d-flex justify-content-between align-items-center mb-15">
            <div class="test-series-main-heading icon-add">
              <h4>{props.data && props.data.name ? props.data.name : ""}{props.data && props.data.code ? ` (${props.data.code})` : ""}</h4>
              <span class="test-series-main-left-heading-act">
                <SystemIcons data={props.data} />
              </span>
            </div>
            <div class="btn-action-block">
              {(props.data.quiz_id == 0 && props?.data?.is_download_allowed==1 && props.data.assFile != '') && <button
                onClick={() =>{handleDownload()}}
                type="button"
                class="btn btn-primary mr-2"
              >
                <i class="fal fa-download"></i>&nbsp; Download
              </button>}
              <button
                onClick={props.backBtn}
                type="button"
                class="btn btn-primary"
              >
                <i class="fal fa-chevron-left"></i> Back
              </button>
            </div>
          </div>

          <div class="test-series-main-heading mb-15">
            <h4>Details</h4>
          </div>
          <AssessmentDetailTab data={props.data} quizId={props.quizId} maxAttempt={props.maxAttempt} step={6} toolTip={props.toolTip}/>        
          <div class="test-series-main-heading mb-15">
            <h4>Result</h4>
            {calculateTimeLeft(props?.data?.close_date_time) && (props?.data?.student_intake_status !== 3) && (props.maxAttempt > props.totalattempt) && (moment(props.data.close_date_time) >= moment()) && (moment(props.data.open_date_time) <= moment()) &&
              <div>
                <span className="attempts-con">
                  Attempts left: {props.maxAttempt - props.totalattempt}
                </span>
                <span onClick={props.reAttemptQuiz} className={`retry-txt`}>
                  Retry
                </span>
              </div>
            }
          </div>
          {props?.data?.ass_type=="Survey" ? <>
          <div class="innerblue-textseries-content blue-bg-blk">
              <div class="row">
                <div class="col-md-8">
                  <div class="assessment-result-content">
                    <div className="assessment-result-st-blk">
                      <p>
                        <strong>Dear {user?.fullname}</strong>
                      </p>
                      <p>Thank you for submission.</p>                       
                    </div>
                  </div>
                </div>                
              </div>
            </div>
          </>
          : <>
          {!((props?.data?.grademixCulc?.lenght > 0 && props?.data?.grademixCulc[0].grade == 'witheld') || props?.data?.assCalculateGradeStatus?.gradeText =='witheld')  &&
          <div class="innerblue-textseries-content blue-bg-blk assessment-result-blk h-100">
            <div class="assessment-result-content">
              {/* <h4>Assessment Results</h4> */}
              {/* <div class="assessment-result-status"> */}
                {/* <AssignmentEaxmStatus data={props.data} /> */}
                {/* <di class="ass-status status-dark-red">
                  <i class="fas fa-circle"></i>Pending
                </di> */}
                {/* {props.data.grade ?
                  <>
                    {props.data.status == "Closed-Marked" ? <ShowResult data={props.data} /> : (<div class="ass-status status-dark-red"><i class="fas fa-circle"></i>Pending</div>)}
                  </>
                  :
                  null
                } */}
                {/* <AppealButton appeal_button={props.data.appeal_button} row_id={props.data.id} /> */}
                {/* <ShowResult data={props.data} /> */}
              {/* </div> */}

              <div class="assessment-result-release-blk">
                <h1>Exam Results to be released in</h1>
                <div id="countdown" class="result-countdown">

                  {countTimer ? (
                    <Countdown
                      date={countTimer}
                      renderer={renderer}
                      zeroPadTime='2'
                    >
                    </Countdown>
                  ) : (
                    <span>
                      <ul>
                        <li>
                          <span id="days">{zeroPad(0)}</span>days
                        </li>
                        <li>
                          <span id="hours">{zeroPad(0)}</span>Hours
                        </li>
                        <li>
                          <span id="minutes">{zeroPad(0)}</span>Minutes
                        </li>
                      </ul>
                    </span>
                  )}
                </div>
              </div>

            </div>
          </div>
          }
          {((props?.data?.grademixCulc?.lenght > 0 && props?.data?.grademixCulc[0].grade == 'witheld') || props?.data?.assCalculateGradeStatus?.gradeText =='witheld') && 
            <div className="result-ticket">

              <div className="result-ticket-inner d-flex justify-content-between">

              <div className="result-ticket-L">
                <h5>Result have been withheld</h5>
                <p>Your marks are being withheld due to outstanding balances on your account, you will have limited access to the portal. Please contact the finance department by logging a ticket below.</p>
              </div>

              <div className="result-ticket-R">
                <b>Your Result: WITHHELD</b>
              </div>
              </div>

              <Link to="/addnewticket" className="btn-white-bordered-ticket"><i class="far fa-ticket-alt"></i> Log A Ticket</Link>
            </div>}
          </>
          }
        </div>
      </div>
    </>
  );
}
export default Step6;
