import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setQuiz, setQuizComplete } from "../../../store/actions";
import ReactHtmlParser from "react-html-parser";
import HtmlParser from "react-html-parser";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CKEditor } from 'ckeditor4-react';
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  convertToRaw,
  RichUtils,
  CompositeDecorator,
  ContentState,
  convertFromHTML,
  convertFromRaw,
} from "draft-js";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { addStudentEvidence, addStudentSignature, removeStudentEvidence, UploadDocument } from "../../../services/QuizService";
import { BASE_URL } from "../../../utils/Constants";
import { FetchFileIcon, GetFileSize, getRandomOrder } from "../../../utils/commonFunction";
import { RenderAttachList } from "../../common/RenderAttachListFeed";
import Swal from "sweetalert2";
import ReviewPracticalQuestion from "./ReviewPracticalQuestion";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Formik, useFormik } from "formik";
import DigitalSignatureComponent from "../../common/DigitalSignatureComponent";

const removeHTMLTagsRegex = /(<([^>]+)>|&nbsp;)/gi;

function OptionList({
  grpId, 
  qInd,
  item,
  index,
  question,
  refreshprops,
  answer,
  setanswer,
}) {
  var data = "";
  try {
    data = answer;
  } catch (error) {
    data = "";
  }
  const dispatch = useDispatch();
  // const onChange = (e, score) => {
  //   var arr = e;
  //   setanswer(arr);
  //   dispatch(setQuiz([item.qid, { ...question, selected: arr, score }]));
  //   refreshprops();
  // };
  const onChange = (e) => {
    var arr = e.target.value;
    setanswer(arr);
    if(grpId){
      dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: arr }]));
    } else {
      dispatch(setQuiz([`qa${item.qid}qa`, { ...question, selected: arr }]));
    }
    refreshprops();
  };
  return (
    // <li
    //   onClick={(event) => onChange(item.oid, item.score)}
    //   className={(data == item.oid) ? 'active-choice' : ''}
    //   id={`customRadio_${index}`}
    //   value={item.oid}
    // >
    //   {HtmlParser(`${item.q_option}`)}
    // </li>
    <div class="custom-control custom-radio">
      <input
        type="radio"
        class="custom-control-input"
        name="example"
        onChange={(event) => onChange(event)}
        // defaultChecked={data == item.oid}
        checked={data == item.oid}
        id={`customRadio_${index}`}
        value={item.oid}
      />
      <label class="custom-control-label" htmlFor={`customRadio_${index}`}>
        <span className={"quiz__single__ans__active__dot " + (data == item.oid ? 'active' : '')}></span>
        <li
          // onClick={(event) => onChange(item.oid, item.score)}
          className={(data == item.oid) ? 'active-choice' : ''}
          id={`customRadio_${index}`}
          value={item.oid}
        >
          {HtmlParser(`${item.q_option}`)}
        </li>
      </label>
    </div>
  );
}

function OptionListCheckbox({
  grpId, 
  qInd,
  item,
  index,
  question,
  refreshprops,
  answer,
  setanswer,
}) {
  var data = "";
  try {
    // data = answer[index];
    if (Array.isArray(answer)) {
      data = answer.indexOf(item.oid + "");
    } else {
      data = "";
    }
  } catch (error) {
    data = "";
  }
  const dispatch = useDispatch();
  const onChange = (e) => {
    var checked = 0;
    // const correctanswer = question.options.filter((itm, i) => itm.score != 0);
    if (e.target.checked) {
      var arr = answer.map((item) => item);
      // arr[index] = e.target.value;
      arr.push(e.target.value);
      setanswer(arr);

      arr.map((data) => {
        if (data != "" && data != null) {
          checked++;
        }
      });
     
      if(grpId){
        dispatch(
          setQuiz([
            `qa${grpId}qa`, qInd,
            {
              ...question,
              selected: arr,
              // score: score == correctscore ? correctscore : score,
              // score: score,
            },
          ])
        );
      } else {
        dispatch(
          setQuiz([
            `qa${item.qid}qa`,
            {
              ...question,
              selected: arr,
              // score: score == correctscore ? correctscore : score,
              // score: score,
            },
          ])
        );
      }
      
    } else {
      var arr = answer.map((item) => item);
      // arr[index] = "";
      const index = arr.indexOf(e.target.value + "");
      if (index > -1) {
        arr.splice(index, 1);
      }
      setanswer(arr);
      arr.map((data) => {
        if (data != "" && data != null) {
          checked++;
        }
      });
      // question?.options.map((itm, i) => {
      //   if(arr.includes(itm.oid.toString())){
      //     score = score + itm.score;
      //   }
      //   // const isExist = arr.indexOf(itm.oid + "") != -1 ? true : false;
      //   // // if (itm.oid == arr[i]) {
      //   // if (isExist) {
      //   //   // score += itm.score;
      //   //   score = itm.score > 0 ? score + itm.score : 0;
      //   // }
      //   // correctscore += itm.score;
      // });
      if(grpId){
        dispatch(
          setQuiz([
            `qa${grpId}qa`, qInd,
            {
              ...question,
              selected: arr,
              // score: score == correctscore ? correctscore : score,
              // score: score,
            },
          ])
        );
      } else {
        dispatch(
          setQuiz([
            `qa${item.qid}qa`,
            {
              ...question,
              selected: arr,
              // score: score == correctscore ? correctscore : score,
              // score: score,
            },
          ])
        );
      }
    }
    refreshprops();
  };
  return (
    <div class="custom-control custom-checkbox">
      <input
        class="custom-control-input"
        name="example"
        type="checkbox"
        value={item.oid}
        id={`customCheck_${index}`}
        // checked={data == item.oid}
        // defaultChecked={data == item.oid}
        checked={
          (data != undefined && data != "" && data != null && data != -1) ||
            data == 0
            ? true
            : false
        }
        // defaultChecked={
        //   (data != undefined && data != "" && data != null && data != -1) ||
        //     data == 0
        //     ? true
        //     : false
        // }
        onChange={(event) => onChange(event)}
      />

      <label class="custom-control-label m-choice-option col-md-12" for={`customCheck_${index}`}>
        {ReactHtmlParser(
          `${item.q_option}`.replace(/<\/?[^>]+>/gi, "")
        )}
      </label>
    </div>
  );
}

// function OptionListMatch({
//   grpId,
//   qInd,
//   item,
//   index,
//   question,
//   refreshprops,
//   answer,
//   setanswer,
//   choice,
//   setchoice,
//   setLeftInnerHeight,
//   finalHeightArr,
//   itemst
// }) {
//   var data = "";
//   try {
//     data = answer[index];
//   } catch (error) {
//     data = "";
//   }
//   const innerRef = useRef(null);
//   const dispatch = useDispatch();
//   const onChange = (e) => {
//     var arr = answer.map((item) => item);
//     arr[index] = e.target.value;
//     setanswer(arr);
//     var ch = choice.map((itm) => itm);
//     var ind = e.nativeEvent.target.selectedIndex;
//     if (e.nativeEvent.target[ind].text != "-Select-") {
//       ch[index] = item.q_option + "___" + e.nativeEvent.target[ind].text;
//     } else {
//       ch[index] = "";
//     }
//     setchoice(ch);
//     var score = 0;
//     var scoreArr = [];
//     question?.options.map((itm, i) => {
//       if (itm.oid == arr[i]) {
//         scoreArr[i] = itm.score;
//         score += itm.score;
//       } else {
//         scoreArr[i] = 0;
//       }
//     });
//     scoreArr.map((item, index) => {
//       if (item > 0) {
//       } else {
//         score = 0;
//       }
//     });
//     if(grpId){
//       dispatch(
//         setQuiz([
//           `qa${grpId}qa`, qInd, 
//           { ...question, selected: arr, choice: ch, score, scoreArr },
//         ])
//       );
//     } else {
//       dispatch(
//         setQuiz([
//           `qa${item.qid}qa`,
//           { ...question, selected: arr, choice: ch, score, scoreArr },
//         ])
//       );
//     }
//     refreshprops();
//   };

//   useEffect(() => {
//     if(innerRef.current){
//       const height = innerRef.current.offsetHeight;
//       setLeftInnerHeight(old=>[...old,height]);
//     }
//   }, []);

//   return item?.q_option !== "" ? (
//     <li class="d-flex list-option p-0" style={{minHeight:`${finalHeightArr[index]}px`,alignItems:"flex-start"}}>
//       <span ref={innerRef} style={{padding:"2px 8px"}}>
//         {ReactHtmlParser(
//           `<span>${item.q_option}</span>`
//         )}
//       </span>
//       {/* <select class="form-control" value={data} onChange={onChange}>
//         <option value={""}>-Select-</option>
//         {question?.options.map((itm) => {
//           return (
//             <option value={itm.oid}>
//               {itm.q_option_match.replace(/<\/?[^>]+>/gi, "")}
//             </option>
//           );
//         })}
//       </select> */}
//     </li>
//   ) : null;
// }

const StudentSignature = (props) => {
  const dispatch = useDispatch();
  const { quiz } = useSelector((state) => state.quiz);
  const quizData = Object.values(quiz);
  const { question, assessmentId, formik, isAssessmentReview,formikData } = props;
  const {setTermCondition} =props.propsData
  const[updateDisable, setUpdateDisable]=useState(null)
  const [required, setRequired]=useState(true);

  const [signatureType, setSignatureType]=useState(null)

  const handleUpdateTermCondition = (event) => {
    setTermCondition(event ? 1 : 0)
    formikData.setFieldValue('is_check_terms_and_conditions', event ? 1 : 0);
    const isChecked = event  
    const updatedQuiz = quizData.find(q => q.qid === 'Student Declaration');
    if (updatedQuiz) {
      updatedQuiz.is_check_terms_and_conditions = isChecked ? 1 : 0;
      dispatch(setQuiz([`qa${question.qid}qa`, updatedQuiz]));
    }
  }; 

  useEffect(() => {
    // setUpdateDisable(((!props.propsData?.assessmentdetails?.is_Allow_Student && !props.propsData?.assessmentdetails?.isStudentAssest) || isAssessmentReview) ? true : null)
    // setUpdateDisable(isAssessmentReview ? true : null)
    setRequired(props.propsData?.practicalAssessmentData?.header?.is_Student_Signature_Required)
    setSignatureType(!isAssessmentReview && formikData.values?.student_Signature ? formikData.values?.student_signature_type : null)
  },[props.propsData])

  return (
    <div className="declaration__box">
      <p className="declarion__text">{question?.declarationText}</p>
      <DigitalSignatureComponent isSignatureSave={props.propsData?.isSignatureSave} setIsSignatureSave={props.propsData?.setIsSignatureSave} setSignItemType={props.propsData?.setSignatureType} required={required} updateDisabled={updateDisable} signature_Type={signatureType} setIsSignature={props.propsData?.setIsSignature} isSignature={props.propsData?.isSignature} readOnlyData={props.propsData?.readOnlyData} readOnly={props.propsData?.readOnly} isCancel={false} formik={formik} signaturePadRef={props.propsData?.signaturePadRef} fileInputRef={props.propsData?.fileInputRef} cDate={props.propsData?.cDate} ipAddress={props.propsData?.ipAddress} setIpAddress={props.propsData?.setIpAddress} setCDate={props.propsData?.setCDate} setIsSig={props.propsData?.setIsSig} />
      <div className="custom-control custom-checkbox text-left box-tm mt-3 pt-1">
        <input
          className={
            "term__box custom-control-input " +
            (formikData.touched.is_check_terms_and_conditions && formikData.errors.is_check_terms_and_conditions
              ? " is-invalid"
              : "")
          }
          type="checkbox"
          name="is_check_terms_and_conditions"
          onChange={(event)=>handleUpdateTermCondition(event.target.checked)}
          onBlur={formikData.handleBlur}
          checked={formikData.values.is_check_terms_and_conditions == 1}
          disabled={isAssessmentReview}
          // disabled={(props.propsData?.readOnly || isAssessmentReview) || (!props.propsData?.assessmentdetails?.is_Allow_Student && !props.propsData?.assessmentdetails?.isStudentAssest)}
          id="customCheck2"
        />
        <label
          className={`custom-control-label color-red ${isAssessmentReview && 'disable-checkbox'}`}
          htmlFor="customCheck2"
        >
          I hereby confirm that I have read, understood and accepted
          all <a href={props.propsData?.practicalAssessmentData?.header?.tandc_url} target="_blank">Terms and Conditions</a> as set out in the application form that the above subject selections are correct, and understand
          that there may be changes made based on the academic outcome.
        </label>
        {Object.keys(formik.errors).map((key) => {
          if (formik.touched[key]) {
            return (
              <div className="invalid-feedback d-block" key={key}>
                {formik.errors[key]}
              </div>
            );
          }
          return null;
        })}
        {Object.keys(formikData.errors).map((key) => {
          if (formikData.touched[key]) {
            return (
              <div className="invalid-feedback d-block" key={key}>
                {formikData.errors[key]}
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>)
}

function OptionShortAnswer({
  grpId, 
  qInd,
  item,
  index,
  question,
  refreshprops,
  answer,
  setanswer,

}) {

  var data = "";
  try {
    data = answer;
  } catch (error) {
    data = "";
  }
  const dispatch = useDispatch();
  const onChange = (e) => {
    var arr = e.target.value;
    setanswer(arr);
    if(grpId){
      dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: arr }]));
    } else {
      dispatch(setQuiz([`qa${question.qid}qa`, { ...question, selected: arr }]));
    }
    refreshprops();
  };

  const ckOnChange = (editor) => {
    setanswer(editor);
    var score = editor == item.q_option ? item.score : 0;
    dispatch(setQuiz([`qa${question.qid}qa`, { ...question, selected: editor, score }]));
    refreshprops();
  }

  return (
    <div class="form-group">
      <textarea
        type="text"
        value={data}
        onChange={onChange}
        class="form-control"
        placeholder="Short Answer"
      ></textarea>
      {/* <CKEditor
        initData={HtmlParser(data)}
        onChange={({ editor }) => {
          // setEditorState(editor.getData());
          ckOnChange(editor.getData())
          // console.log('the value of the current editor is ', editor)
        }}
        onInstanceReady={({ editor }) => {
          editor.setData(HtmlParser(data));
        }}
        config={{
          preset: 'full',
          // use these plugins if ckeditor preset is set to standard and not full
          extraPlugins: 'forms,iframe,font,colordialog,colorbutton,embed,emoji,justify,bidi,exportpdf,templates,smiley,showblocks,selectall,scayt,find,entities,basicstyles',
          // extraPlugins:'dialog',
          // toolbar: 'full',
          //below code is to run videos in ckeditor
          embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
        }}
      /> */}
    </div>
  );
}

function OptionLongAnswer({ grpId, qInd, question, refreshprops, answer, setanswer }) {
  var data = "";
  try {
    data = answer;
  } catch (error) {
    data = "";
  }
  const dispatch = useDispatch();
  const onChange = (e) => {
    var arr = e.target.value;
    setanswer(arr);
    // dispatch(setQuiz([`qa${question.qid}qa`, { ...question, selected: arr }]));
    if(grpId){
      dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: arr }]));
    } else {
      dispatch(setQuiz([`qa${question.qid}qa`, { ...question, selected: arr }]));
    }
    // refreshprops();
  };
  // const ckOnChange = (editor) => {
  //   setanswer(editor);
  //   if(grpId){
  //     dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: editor }]));
  //   } else {
  //     dispatch(setQuiz([`qa${question.qid}qa`, { ...question, selected: editor }]));
  //   }
  //   refreshprops();
  // }

  return (
    <div class="form-group">
      <textarea
        type="text"
        value={HtmlParser(data)}
        onChange={onChange}
        class="form-control"
        placeholder="Long Answer"
      ></textarea>
      {/* <CKEditor
        initData={HtmlParser(data)}
        onChange={({ editor }) => {
          // setEditorState(editor.getData());
          ckOnChange(editor.getData())
          // console.log('the value of the current editor is ', editor)
        }}
        onInstanceReady={({ editor }) => {
          editor.setData(HtmlParser(data));
        }}
        config={{
          preset: 'full',
          // use these plugins if ckeditor preset is set to standard and not full
          extraPlugins: 'forms,iframe,font,colordialog,colorbutton,embed,emoji,justify,bidi,exportpdf,templates,smiley,showblocks,selectall,scayt,find,entities,basicstyles',
          // extraPlugins:'dialog',
          // toolbar: 'full',
          //below code is to run videos in ckeditor
          embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
        }}
      /> */}
    </div>
  );
}

function FillInTheBlanks({
  grpId,
  qInd,
  question,
  refreshprops,
  answer,
  setanswer,
}) {
  // console.log("_________Fill In The Blank Answer_____",answer);
  // const inputString = "I am ___ years old and my annual income is ___ and my favorite color is ___ and my favorite food is ___";
  const dispatch = useDispatch();
  const inputString = question.question.replace(removeHTMLTagsRegex, "").replaceAll("&#39;","'");

  const numInputs = inputString.split('___').length - 1;

  const [inputValues, setInputValues] = useState(Array(numInputs).fill(''));
  const [answers, setAnswers] = useState([]);
  useEffect(()=>{
    setInputValues(Array(numInputs).fill(''));
    if(Array.isArray(answer) && answer.length > 0){
      setAnswers(answer?.map(a=>typeof a?.q_option_match === "string" ? a?.q_option_match : ""));
    }
  },[answer]);

  function handleChange(event, index) {
    const newValues = [...inputValues];
    newValues[index] = event.target.value;
    // console.log("new fill selected->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", newValues);

    setInputValues(newValues);

    const newAnswers = [...answers];
    newAnswers[index] = event.target.value;
    // setAnswers(newAnswers);
    // setanswer(newAnswers);
    
    // console.log("_________new fill selected_________", newAnswers);
    const selected = newAnswers?.map((item, ind) => {

      return { ...question.options[ind], q_option_match: item};
    });
    setanswer(selected);

    if(question.options.length){
      question.options.map(item => {
        if(grpId){
          dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: selected}]));
        } else {
          dispatch(setQuiz([`qa${item.qid}qa`, { ...question, selected: selected}]));
        }
        refreshprops();
      })
    }else{
      if(grpId){
        dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: selected}]));
      } else {
        dispatch(setQuiz([`qa${question.qid}qa`, { ...question, selected: selected}]));
      }
      refreshprops();
    }
  }
  const parts = inputString.split('___');

  const inputBoxes = parts.flatMap((part, index) => {
    const inputIndex = index % inputValues.length;
    // console.log("--------------------------->>>>>>>>",inputIndex,index);
    if (index === parts.length - 1) {
      return <span key={index}>{part}</span>;
    } else {
      return [
      <span key={`part-${index}`}>{part}</span>,
      <input id={`input-${index}`} key={`input-${index}`} className={"form-control"} type="text" onChange={(event) => handleChange(event, inputIndex)} value={answer?.length ? answer[inputIndex]?.q_option_match : inputValues[inputIndex]} />
      ];
    }
  });

  const inputStringWithValues = parts.reduce((result, part, index) => {
    const inputIndex = index % inputValues.length;
    if (index === parts.length - 1) {
      return result + part;
    } else {
      return result + part + inputValues[inputIndex];
    }
  }, '');

  return (
    <div className="text-input-list">
      {inputBoxes}
      <br />
      {/* <button onClick={() => setAnswers([])}>Clear answers</button>
      <ul>
        {answers.map((answer, index) => (
          <li key={index}>{answer}</li>
        ))}
      </ul> */}
    </div>
  );
}

// Choice Matrix
function ChoiceMatrix({
  grpId,
  qInd,
  question,
  refreshprops,
  answer,
  setanswer,
}) {
  // console.log(":::::::------answer-----:::::",answer);
  const dispatch = useDispatch();
  const columns = question.options.length ? JSON.parse(question.options[0].q_option).map(item=>item.titleValue) : [];
  const rows = question.options.length ? question?.options.map(item => ({ ...item, q_option: JSON.parse(item.q_option) })) : [];
  // console.log(":::ROWS:::",rows);
  const colWidth = { width: `${100 / (columns.length + 1)}%` };

  const [inputValues, setInputValues] = useState(rows.map(item => item.q_option.map(() => (0))));

  const handleChange = (rInd, colInd, optId) => {
    let newValues = [...inputValues];
    newValues[rInd] = newValues[rInd].map((item, ind) => (ind === colInd ? 1 : 0));
    // console.log(":::::::::::::::", newValues);
    setInputValues(newValues);

    // matching user answer
    const matched = rows.map((row, ind) => {
      if (optId === row.oid) {
        let matchingOrder = false;
        for (let i = 0; i < row.q_option.length; i++) {
          const isSelected = row.q_option[colInd].isSelected === true ? 1 : 0;
          if (isSelected === newValues[rInd][colInd]) {
            matchingOrder = true;
            break;
          } else {
            matchingOrder = false;
          }
        }
        // console.log("::::matching->",matchingOrder);
        return { ...row, q_option_match: row.q_option[colInd], score: matchingOrder ? row.score : 0 };
      } else {
        if (answer?.length) {
          return { ...row, q_option_match: answer[ind].q_option_match, score: answer[ind].score };
        } else {
          return { ...row, q_option_match: "", score: 0 }
        }
      }
    });
    
    // console.log(":::::::::::Matched::::::::::>",matched);
    setanswer(matched);
    // score
    let score = 0;
    matched.map(item=>{score+=item.score;return null;});
    if(grpId){
      dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: matched, score: score}]));
    } else {
      dispatch(setQuiz([`qa${question.qid}qa`, { ...question, selected: matched, score: score}]));
    }
    refreshprops();
  }

  return (
    <table className="choice__matrix__wrapper">
      <thead>
        <tr>
          <th style={colWidth}></th>
          {columns.map((col, colInd) => (<th key={colInd} style={colWidth}>{col}</th>))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rInd) => (
          <tr key={rInd}>
            <td style={colWidth}>{row?.titleValue}</td>
            {columns.map((col, colInd) => (<td key={colInd} style={colWidth}>
              <input type="radio" name={row?.oid} checked={(answer && answer[rInd] && answer[rInd]?.q_option) ? (answer[rInd]?.q_option[colInd]?.titleValue === answer[rInd]?.q_option_match?.titleValue ? true : false) : (inputValues[rInd][colInd] === 1 ? true : false)} onChange={() => handleChange(rInd, colInd, row.oid)} />
            </td>))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
// Document Upload
function DocumentUpload({
  grpId,
  qInd,
  question,
  refreshprops,
  answer,
  setanswer,
  evidenceType 
}) {
  // console.log(":::::::------answer-----:::::",answer);
  const dispatch = useDispatch();
  const [filess, setFiless] = useState([]);

  useEffect(()=>{
    setFiless(answer && answer?.length > 0 ? answer : []);
  },[answer]);
  
  const handleSubmit = (files, allFiles) => { 
    // console.log("::::::FILES:::::::",files,allFiles); 
    // setanswer(files.map(f=>f.file));
    refreshprops();
    // let filesURL = [];
    const formData = new FormData();
    files.forEach((f,i) => {
      formData.append(`document[${i}]`, f.file);
    });
    UploadDocument(formData).then(res=>{
      // console.log("_____________ Uploaded FILES ______________",res.data);
      setanswer([...filess,...res.data]);
      // filesURL.push(res.data);
      if(grpId){
        dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: [...filess,...res.data] }]));
      } else {
        dispatch(setQuiz([`qa${question.qid}qa`, { ...question, selected: [...filess,...res.data] }]));
      }
    }).catch(error=>{
      console.error(error);
    });
    
    allFiles.forEach((file) => file.remove());
  };
  // remove uploaded files
  const handleRemoveFile = (ind)=>{
    let file = filess;
    file.splice(ind.i,1);
    setFiless(file);
    if(grpId){
      dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: file }]));
    } else {
      dispatch(setQuiz([`qa${question.qid}qa`, { ...question, selected: file }]));
    }
  }

  const getFileName = (url) => {
    const name = url?.split("/").reverse()[0];
    return name;
  }

  return <div className="profile-doc-upload-blk">
    <div className="row">
      {/* show/preview uploaded files */}
      {(filess && filess?.length > 0) ? filess?.map((f, i) => {
        return (
          <div className="col-md-4 mb-4" key={i}>
            {RenderAttachList({ fileName: getFileName(f?.filePath), size: f.size, filePath: f?.filePath, handleRemoveFile: handleRemoveFile, showClose: true, i })}
          </div>
        )
      }) : null}
    </div>
    <div className="profile-doc-upload">
      {/* file uploader */}
      <Dropzone
        onSubmit={handleSubmit}
        classNames={{ submitButton: 'btn btn-primary', submitButtonContainer: ' btn-upload' }}
        styles={{ dropzone: { /*minHeight: 225*/ } }}
        accept=".zip,.jpg,.png,.jpeg,.pdf,.doc,.docx,.txt,.mp4,.mp3,.otf"
        inputContent={
          <lable>
            <i className="fas fa-file-upload"></i>
            <h4>
              To submit question, Drop your file here, or <span>browse</span>
            </h4>
            <p>Supports: PDF, DOCX and more</p>
          </lable>
        }
        maxSizeBytes="1000MB"
        submitButtonContent={<div class="submit-files"><i class="fal fa-check"></i> Submit Files</div>}
      />
    </div>
  </div>
}

//practical assessement
function PracticalAssessementDocUpload({
  key,
  grpId,
  qInd,
  question,
  refreshprops,
  answer,
  setanswer,
  evidenceType,
  evidenceStatus,
  files,
  setFiles,
  setPhotoFiles,
  setVideoFiles,
  setDocFiles,
  photoFiles,
  videoFiles,
  docFiles,
  setquestion,
  setIsRender,
  assessmentId,
  assessmentDetails
}) {
  // console.log(":::::::------answer-----:::::",answer);
  const dispatch = useDispatch();
  const [evidenceList, setEvidenceList] = useState([]);
  const { quiz } = useSelector((state) => state.quiz);
  const quizData = Object.values(quiz);
  const [fileInProgress, setFileInProgress] = useState(0);
  const [fileStatus, setFileStatus] = useState({});
  const [openEvidence, setOpenEvidence] = useState({});
  const location=useLocation()
  // useEffect(() => {
  //   setFiless(answer && answer?.length > 0 ? answer : []);
  // }, [answer]);

  const getUploadParams = ({ meta }) => {
    return {
      url: 'https://httpbin.org/post'
    }
  }

  const handleSubmit = (key, files, allFiles) => {
    let questionId = grpId || question.qid;
    refreshprops();
   
    
  
    // Get existing files for the current question

    const FilesPhoto = Object.keys(photoFiles).length > 0 && photoFiles[questionId]?.length ? photoFiles[questionId] : [];
    const fileVideo = Object.keys(videoFiles ).length > 0 && videoFiles[questionId]?.length ? videoFiles[questionId] : [];
    const fileDoc = Object.keys(docFiles).length > 0 && docFiles[questionId]?.length ? docFiles[questionId] : [];
    
  
    // Transform files to common format
    const transformFileToCommonFormat = (file) => ({
      name: file?.meta?.name,
      size: file?.meta?.size,
      type: file?.meta?.type,
      uploadedDate: file?.meta?.uploadedDate,
      status: file?.meta?.status,
      previewUrl: file?.meta?.previewUrl,
    });
  
    // Combine and transform all existing files
    const combinedOldFiles = [...FilesPhoto, ...fileVideo, ...fileDoc].map(transformFileToCommonFormat);
    const newFileData = files.map(transformFileToCommonFormat);
    const mergedFiles = [...combinedOldFiles, ...newFileData];
  
    // Create arrays for practical criteria handling
    const fileIds = mergedFiles.map((_, index) => index);
    const fileUrls = mergedFiles.map(file => file.previewUrl);
    const choice = mergedFiles.map(file => `${file.previewUrl}___${file.type}`);
  
    // Handle lecturer observation if needed
    let selected = [...fileIds];
    let selectedItems = [...fileUrls];
    let finalChoice = [...choice];
    
    if (question?.observation_date > 0) {
      selected.unshift(question.lecturer_observation_toggle || 0);
      selectedItems.unshift(question.lecturer_observation_toggle ? "Observed" : "Not Observed");
      finalChoice.unshift(`lecturer_observation___${question.lecturer_observation_toggle}`);
    }
    // Create updated question object
    const updatedQuestion = {
      ...question,
      practicalFiles: mergedFiles,
      selected,
      choice: finalChoice,
      selectedItems
    };
  
    // Update the quiz state based on grouping
      dispatch(setQuiz([`qa${question.qid}qa`, updatedQuestion]));

    // Update local question state
    setquestion(updatedQuestion);

   // Update specific file type states
    if (key === "evidence_photo") {
      setPhotoFiles((prev) => ({
        ...prev,
        [questionId]: [...(prev[questionId] || []), ...files],
      }));
    } else if (key === "evidence_video") {
      setVideoFiles((prev) => ({
        ...prev,
        [questionId]: [...(prev[questionId] || []), ...files],
      }));
    } else {
      setDocFiles((prev) => ({
        ...prev,
        [questionId]: [...(prev[questionId] || []), ...files],
      }));
    }
    // Clean up files
    allFiles.forEach((file) => file.remove());

    const submittedFiles = () => {
      const formData = new FormData();
      formData.append("question_id", question.qid);
      formData.append("assessment_id", assessmentId);

      // Filter and append files based on type    
      if (files?.length > 0) {
        files.forEach((file, idx) => {
          if (file.file.type.includes('image')) {
            formData.append(`photo[${idx}]`, file.file);
          } else if (file.file.type.includes('video')) {
            formData.append(`video[${idx}]`, file.file);
          } else if (file.file.type.includes('document') || file.file.type.includes('pdf')) {
            formData.append(`document[${idx}]`, file.file);
          }
        });
      }

      addStudentEvidence(formData)
        .then((res) => { })
        .catch((error) => {
          if (error.response.status == 400 && error?.response?.data?.alreadySubmitted == true) {
            Swal.fire({
              icon: "info",
              title: "Assessment already submitted",
              text: error.response.data.message,
            }).then(() => {
              // props.initiateReload();
            })
          }
        });
    }
    if(!location.pathname.includes("/courseinfo/quizinfo")){
      submittedFiles()
    }

    // Trigger re-render after delay
    const timeoutId = setTimeout(() => {
      setIsRender(prev => !prev);
    }, 1000);
  
    return () => clearTimeout(timeoutId);
  };

  // remove uploaded files
  const handleRemoveFile = (data) => {
    if (!data) return;  
    const { types: type, f: fileData, i: fileIndex } = data;
    const questionId = grpId || question.qid;
  
    const updateFiles = (setter, files) => {
      setter((prev) => ({
        ...prev,
        [questionId]: files.filter((_, i) => i !== fileIndex),
      }));
    };
  
    const updateQuizData = () => {
      const updatedQuizData = quizData.map((q) => {
        if (q.qid === questionId) {
          const updatedQuestion = {
            ...q,
            practicalFiles: q.practicalFiles.filter((_, i) => i !== fileIndex),
          };
          dispatch(setQuiz([`qa${questionId}qa`, updatedQuestion]));
          setquestion(updatedQuestion);
          return updatedQuestion;
        }
        return q;
      });
      return updatedQuizData;
    };
  
    const handleFileRemoval = () => {
      if (type === "evidence_photo") {
        updateFiles(setPhotoFiles, photoFiles[questionId] || []);
      } else if (type === "evidence_video") {
        updateFiles(setVideoFiles, videoFiles[questionId] || []);
      } else {
        updateFiles(setDocFiles, docFiles[questionId] || []);
      }
      updateQuizData();
    };
  
    Swal.close();
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (!result.value) return;
  
      if (fileData) {
        removeStudentEvidence({ practical_submission_file_id: fileData.id })
          .then(() => {
            handleFileRemoval();
            Swal.fire({
              icon: "success",
              title: "Files removed successfully",
              showConfirmButton: true,
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Files could not removed.",
              text: error.message,
            });
          });
      } else {
        handleFileRemoval();
        Swal.fire({
          icon: "success",
          title: "Files removed successfully",
          showConfirmButton: true,
        });
      }
    });
  };

  const getFileName = (url) => {
    const name = url?.split("/").reverse()[0];
    return name;
  }

  const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes < 1024) {
      return `${sizeInBytes} bytes`;
    } else if (sizeInBytes < 1048576) {
      return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    } else {
      return `${(sizeInBytes / 1048576).toFixed(2)} MB`;
    }
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    let fileQueue = {...fileStatus, [meta.id]:status};
    const inProgressStatus = ["preparing", "ready", "ready", "getting_upload_params", "uploading", "restarted"]
    const fileProgressCount = Object.keys(fileQueue).filter(item => inProgressStatus.includes(fileQueue[item])).length;
    setFileInProgress(fileProgressCount);
    if(inProgressStatus.includes(status)){
      // props.setFileSubmitted(false);
    }
    setFileStatus({...fileQueue})    
  }
  
  const renderEvidenceTypeItem = (type, marked = false) => {
    switch (type?.toLowerCase().replace(/\s+/g, "_")) {
      case "camera":
        return <span className="evidence__type__item"><i className="far fa-camera" />Photo Evidence {marked ? <i className="far fa-check-circle item__green" /> : null}</span>;
      case "video":
        return <span className="evidence__type__item"><i className="far fa-video" />Video Evidence {marked ? <i className="far fa-check-circle item__green" /> : null}</span>;
      case "document":
        return <span className="evidence__type__item"><i className="far fa-file-alt" />Document Evidence {marked ? <i className="far fa-check-circle item__green" /> : null}</span>;
      default:
        // return
        return
    }
  }

  useEffect(() => {
    if (question) {
      const updatedEvidenceList = [
        { key: 'evidence_photo', value: question.evidence_photo },
        { key: 'evidence_video', value: question.evidence_video },
        { key: 'evidence_document', value: question.evidence_document },
      ];
      setEvidenceList(updatedEvidenceList);
    }
  }, [question]); 

  return <div className={`${!question?.evidence_lecturer_observation ? 'padding__bottom__none' : ''} practical__assessment__questionList`}>    
    {question?.question_user_feedback ? <div className="feedback__section">
        <span className="feedback__section__title">Feedback</span>
        <div className="feedback__section__content">
          <p>{question?.question_user_feedback}</p>
          {question?.question_user_feedback_file ? <div className="row">
            <div className="col-md-4 mt-3">
              {RenderAttachList({
                fileName: getFileName(question?.question_user_feedback_file),
                // size: formatFileSize(f?.file_size),
                filePath: question?.question_user_feedback_file,
                showClose: false,
              })}
            </div>
          </div> : null}
        </div>
      </div> : null}
    {evidenceList?.length > 0 ? evidenceList?.map((item => item?.value ? <div className="profile-doc-upload-blk practical__assessment__box custom__accordion__box" key={item.key}>
      <div className="accordion__head review__box__head">
        {/* Set currentFiles based on item.key */}
        <div
          onClick={() => setOpenEvidence(prev => ({
            ...prev,
            [`${item.key}_${question.qid}`]: !prev[`${item.key}_${question.qid}`]
          }))}
        >
          {item.key == 'evidence_document' && item.value
            ? renderEvidenceTypeItem('document')
            : null}
          {item.key == 'evidence_photo' && item.value
            ? renderEvidenceTypeItem('camera')
            : null}
          {item.key == 'evidence_video' && item.value
            ? renderEvidenceTypeItem('video')
          : null}
        </div>
        <span type="button"
          onClick={() => setOpenEvidence(prev => ({
            ...prev,
            [`${item.key}_${question.qid}`]: !prev[`${item.key}_${question.qid}`]
          }))} className="">
          <i
            className={`evidence__toggle__btn ${openEvidence[`${item.key}_${question?.qid}`]
                ? "fal fa-chevron-up"
                : "fal fa-chevron-down"
              }`}
          />
        </span>
      </div>
      <div className={`evidence__bottom__section ${openEvidence[`${item.key}_${question.qid}`] ? 'd-block' : 'd-none'}`}>
          {(() => {
          const questionId = grpId || question.qid; // Get the current question ID
          // Get the current files based on the evidence type
          const currentFiles =
            item.key == "evidence_photo"
              ? photoFiles[questionId]
              : item.key == "evidence_video"
                ? videoFiles[questionId]
                : item.key == "evidence_document"
                  ? docFiles[questionId]
                  : [];

          return (
            <div className="evidence__files__box">
                {/* Render the files if available */}
                {currentFiles?.length > 0 ? currentFiles.map((f, i) => (
                  <div className="col-md-4 evidence__files" key={i}>
                    {f?.meta && Object.keys(f.meta).length > 0 ? (
                      RenderAttachList({
                        fileName: f?.file?.name,
                        file: f?.file,
                        size: formatFileSize(f?.file?.size),
                        filePath: f?.file?.name,
                        handleRemoveFile,
                        types: item.key,
                        showClose: !assessmentDetails?.is_Allow_Student && !assessmentDetails?.isStudentAssest ? false : true,
                        i,
                      })
                    ) : (
                      RenderAttachList({
                        fileName: getFileName(f?.file_url),
                        size: formatFileSize(f?.file_size),
                        filePath: f?.file_url,
                        handleRemoveFile,
                        types: item.key,
                        showClose: !assessmentDetails?.is_Allow_Student && !assessmentDetails?.isStudentAssest ? false : true,
                        i,
                        f
                      })
                    )}
                  </div>
                )) : null}
            </div>
          );
        })()}
        
        <div className="profile-doc-upload file__upload__input">
          {/* file uploader */}
          <Dropzone
            onSubmit={(files, allFiles) => handleSubmit(item.key, files, allFiles)}
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            disabled={!location.pathname.includes("/courseinfo/quizinfo") ? !assessmentDetails?.is_Allow_Student && assessmentDetails?.isStudentAssest != 1 : false}
            classNames={{ submitButton: 'btn btn-primary', submitButtonContainer: ' btn-upload' }}
            styles={{ dropzone: { /*minHeight: 225*/ } }}
            accept={
              item.key == "evidence_photo"
                ? ".jpg,.png"
                : item.key == "evidence_video"
                ? ".mp4"
                : item.key == "evidence_document"
                ? ".pdf,.doc,.docx,.txt,.zip,.otf,.xls,.xlsx,.csv,.ppt,.pptx,.rtf,.odt,.ods,.odp"
                : ""
            }
            inputContent={
              <lable>
                <i className="fas fa-file-upload"></i>
                <h4>
                  To upload your signature, Drop your file here, or <span>click here to browse</span>
                </h4>
                <p>
                  Supported Files:
                  {item.key === "evidence_photo"
                    ? "JPG and PNG"
                    : item.key === "evidence_video"
                      ? "MP4"
                      : "PDF, DOCX, and more"}
                </p>
              </lable>
            }
            maxSizeBytes="1000MB"
            submitButtonContent={<div class="submit-files"><i class="fal fa-check"></i> Submit Files</div>}
          />
        </div>
      </div>
    </div> : null)) : null}
  </div>
}

function DisplayQuestion(props) {
  const { question, quesno, setquestion, grpId, qInd, showQuesType,assessementQuestionList } = props;
  const { examLaunched,isAssessmentReview,isPracAssessmentLaunched } = useSelector(state=>state.examLaunched);
  var timer = null;
  const [answer, setanswer] = useState([]);
  const [choice, setchoice] = useState([]);
  const [singleanswer, setsingleanswer] = useState("");
  const [typeanswer, settypeanswer] = useState("");
  const [refresh, setrefresh] = useState(false);
  const [itemst, setItemst] = useState([]);
  const dispatch = useDispatch();
  // const [leftInnerHeight, setLeftInnerHeight] = useState([]);
  const rightInnerRefs = useRef([]);
  const leftInnerRefs = useRef([]);
  const [finalHeightArr, setFinalHeightArr] = useState([]);
  const [isRender, setIsRender] = useState(false);
  const [showDocument, setShowDocument] = useState([]);
  const [docsSize, setDocsSize] = useState([]);

  const refreshprops = () => {
    setrefresh(!refresh);
    props.setrefresh(!props.refresh);
  };

  useEffect(() => {
    if (question?.question_type == "Practical Criteria") {
      let tempArr = JSON.parse(JSON.stringify(question));
      
      // Create arrays for files
      const fileIds = question?.practicalFiles?.map(file => file.id) || [];
      const fileUrls = question?.practicalFiles?.map(file => file.file_url) || [];
      
      // Initialize arrays
      let selected = [...fileIds];
      let selectedItems = [...fileUrls];
      let choice = question?.practicalFiles?.map(file => 
        `${file.file_url}___${file.type}`
      ) || [];
      
      // Only add lecturer observation if observation_date > 0
      if (question.observation_date > 0 || question.lecture_observation_updated) {
        selected.unshift(question.lecturer_observation_toggle || 0);
        selectedItems.unshift(question.lecturer_observation_toggle ? "Observed" : "Not Observed");
        choice.unshift(`lecturer_observation___${question.lecturer_observation_toggle}`);
      }

  
      const updatedQuestion = {
        ...question,
        selected,
        choice,
        selectedItems
      };
        dispatch(setQuiz([`qa${question.qid}qa`, updatedQuestion]));
      // Set the local state
      setItemst(tempArr);
      
      // Trigger re-render after a delay
      const timeoutId = setTimeout(() => {
        setIsRender(prevState => !prevState);
      }, 1000);
  
      return () => clearTimeout(timeoutId);
    }
    else if (question?.question_type === "Match the Column" || question?.question_type === "Order List") {
      let tempArr = JSON.parse(JSON.stringify(question));
      let tempOption = tempArr?.options ?? [];
      let newOptionTemp = [];
  
      if (question.selected.length > 0) {
        if (question?.question_type === "Order List") {
          newOptionTemp = question.selected.reduce((acc, selectedId) => {
            const matchingOptions = question?.options.filter(opt => opt.oid === selectedId);
            return [...acc, ...matchingOptions];
          }, []);
        }
        
        if (question?.question_type === "Match the Column") {
          newOptionTemp = question.selected.map((_, ind) => {
            const matchingOption = question?.options[ind];
            return matchingOption ? { ...matchingOption } : null;
          }).filter(Boolean);
        }
      }
  
      if (newOptionTemp.length > 0) {
        tempArr.options = newOptionTemp;
      } else {
        if (tempOption && question?.question_type === "Order List") {
          shuffleArray(tempOption);
        }
        if (tempOption) {
          tempArr.option = tempOption;
        }
      }
  
      if ((question?.question_type === "Order List" || question?.question_type === "Match the Column") && 
          Array.isArray(question?.selected) && 
          question?.selected.length === 0) {
        
        const selected = tempOption.map(opt => opt.oid);
        const selectedItems = tempOption.map(opt => opt.q_option);
        
        const choice = question?.options.map((item, i) => {
          const opt = tempOption[i];
          if (!opt) return null;
          
          return question?.question_type === "Order List" 
            ? `${item.q_option}___${opt.q_option}`
            : `${item.q_option}___${opt.q_option_match}`;
        }).filter(Boolean);
  
        const updatedQuestion = {
          ...question,
          selected,
          choice,
          selectedItems
        };
  
        if (grpId) {
          dispatch(setQuiz([`qa${grpId}qa`, qInd, updatedQuestion]));
        } else {
          dispatch(setQuiz([`qa${question.qid}qa`, updatedQuestion]));
        }
      }
  
      setItemst(tempArr);
      const timeoutId = setTimeout(() => {
        setIsRender(prevState => !prevState);
      }, 1000);
  
      return () => clearTimeout(timeoutId);
    }
  }, [question]);

  useEffect(() => {
    try {
      clearTimeout(timer);
      timer = null;
      // setanswer(question.selected);
      timer = setInterval(() => {
        if (question.qid != null) {
          localStorage.setItem(
            `@qa${question.qid}qa`,
            parseInt(localStorage.getItem(`@qa${question.qid}qa`) || 0) + 1
          );
        }
      }, 1000);
    } catch (error) {
      // setanswer(new Array());
    }

    try {
      if(question.question_type=="Short Answer" && Array.isArray(question?.selected) && question?.selected.length){
        setsingleanswer(question?.selected[0]?.q_option);
      }else{
        setsingleanswer(question.selected);
      }
    } catch (error) {
      setsingleanswer("");
    }

    try {
      if(question.question_type=="Long Answer" && Array.isArray(question?.selected) && question?.selected.length){        
        settypeanswer(question?.selected[0]?.q_option);
      }else{
        settypeanswer(question.selected);
      }
    } catch (error) {
      settypeanswer("");
    }

    try {
      setchoice(question.choice);
    } catch (error) {
      setchoice(new Array());
    }

    return () => clearInterval(timer);
  }, [props.question]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = getRandomOrder(i);
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  const clearAnswer = () => {
    setsingleanswer("");
    setchoice(new Array());
    setanswer(new Array());
    settypeanswer("");
    refreshprops();
  };

  useEffect(() => {
    // clearAnswer();
  }, [props.clear]);

  useEffect(() => {
    // localStorage.removeItem("totaltime");
    // var ttimer = setInterval(() => {
    //   localStorage.setItem(
    //     "totaltime",
    //     parseInt(localStorage.getItem("totaltime") || 0) + 1
    //   );
    //   refreshprops();
    // }, 1000);
    return () => {
      // clearInterval(ttimer);
      // ttimer = null;
      // localStorage.removeItem("totaltime");
      // for (let i = 0; i < localStorage.length; i++) {
      //   let value = localStorage.key(i);
      //   if (value.startsWith("@")) {
      //     localStorage.removeItem(value);
      //   }
      // }
      // setanswer(new Array());
      setsingleanswer("");
      settypeanswer("");
    };
  }, []);

  function onDragEnd(result, Arr) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      Arr,
      result.source.index,
      result.destination.index
    );
    let temp = {...itemst}
    // console.log("before>>",temp)
    temp.options = [...items]
    // console.log("after>>",temp)
    setItemst({...temp})
    
    const optionRearranged = question?.options.map((opt, ind) =>({...opt, q_option_match : items[ind].q_option_match}));

    let arr = [];
    let selectedItemArr = [];
    let ch = choice.map((item) => item);
    // console.log('the items are like here ', items)
    temp.options.map(i => {
      arr.push((i.oid).toString())
      selectedItemArr.push(i?.q_option);
    })
    question.options.forEach((i, indexi) => {
      items.forEach((j, indexj) => {
        if (indexi === indexj) {
          ch[indexi] = i.q_option + '___' + j.q_option_match;
        }
      });
    });
    var score = 0;
    var scoreArr = [];
    const data = question?.options.map((itm, i) => {
      let itemScore = 0;
      items.forEach((j, indexj) => {
        if (i === indexj) {
          if (itm.oid === j.oid) {
            scoreArr.push(j.score);
            score += itm.score;
            itemScore = j.score;
          } else {
            scoreArr.push(0);
            itemScore = 0;
          }
        }
      });
      return data;
    });
    // console.log('the XXXXXXX in the arr is ', score, scoreArr)
    // scoreArr.map((item, index) => {
    //   if (item > 0) {
    //   } else {
    //     score = 0;
    //   }
    // });
    // console.log('_____________ch', ch)
    // console.log('_____________score', score)
    // console.log('_____________scoreArr', scoreArr)
    if(question?.question_type == "Match the Column"){
      if(grpId){
        dispatch(
          setQuiz([
            `qa${grpId}qa`, qInd,
            { ...question, options : optionRearranged, selected: arr, choice: ch, score, scoreArr, selectedItems: selectedItemArr  },
          ])
        );
      } else {
        dispatch(
          setQuiz([
            `qa${items[0].qid}qa`,
            { ...question, options : optionRearranged, selected: arr, choice: ch, score, scoreArr, selectedItems: selectedItemArr },
          ])
        );
      }
    }else{
      if(grpId){
        dispatch(
          setQuiz([
            `qa${grpId}qa`, qInd,
            { ...question, selected: arr, choice: ch, score, scoreArr, selectedItems: selectedItemArr  },
          ])
        );
      } else {
        dispatch(
          setQuiz([
            `qa${items[0].qid}qa`,
            { ...question, selected: arr, choice: ch, score, scoreArr, selectedItems: selectedItemArr },
          ])
        );
      }
    }
    refreshprops();
  }

  useEffect(()=>{    
    setanswer(question.selected);
    setchoice(question.choice);
    if(question.question_type=="Long Answer" && Array.isArray(question?.selected) && question?.selected.length){      
      settypeanswer(question?.selected[0]?.q_option);
    }else{
      settypeanswer(question.selected);
    }
    if(question.question_type=="Short Answer" && Array.isArray(question?.selected) && question?.selected.length){
      setsingleanswer(question?.selected[0]?.q_option);
    }else{
      setsingleanswer(question.selected);
    }
    if (question?.document) {
      const docArray = JSON.parse(question?.document);
      const docFiles = docArray.map(item => {
        const fileUrl = BASE_URL + "/" + item.replaceAll("/home/myaie/public_html/","").replace("public/","");
        return { url: fileUrl, name: item.split("/").at(-1), size: "0" }
      })
      setShowDocument(docFiles);
      docFiles.map(async item => {
        const size = await GetFileSize(item.url);
        setDocsSize(old=>[...old,size])
        return size;
      })
      // console.log("_______FILE", docFiles);
    }

    return ()=>{
      setShowDocument([]);
      setDocsSize([]);
    }
  },[question])

  useEffect(()=>{
    let lInnerSidearr = [];
    if(leftInnerRefs.current.length){
      leftInnerRefs.current.forEach((ref, index) => {
        if (ref.current) {
          const height = ref.current.clientHeight;
          lInnerSidearr.push(height);
        }
      });
    }

    let rInnerSidearr = [];
    if(rightInnerRefs.current.length){
      rightInnerRefs.current.forEach((ref, index) => {
        if (ref.current) {
          const height = ref.current.clientHeight;
          rInnerSidearr.push(height);
        }
      });
    }
    // console.log("____________________L INNER", lInnerSidearr);
    // console.log("____________________R INNER", rInnerSidearr);
    if(lInnerSidearr.length && rInnerSidearr.length){
      setFinalHeightArr(compareArrays(lInnerSidearr, rInnerSidearr));
    }
  },[itemst,isRender]);

  const compareArrays = (arr1, arr2) => {
    const result = [];
    for (let i = 0; i < Math.max(arr1.length, arr2.length); i++) {
      const val1 = arr1[i] || Number.NEGATIVE_INFINITY;
      const val2 = arr2[i] || Number.NEGATIVE_INFINITY;
      result.push(Math.max(val1, val2));
    }
    return result;
  }

  // const removeLastBRTag = (text)=>{
  //   if(text){
  //     let splitedQues = text?.toString().split("<p>");
  //     let improvedText = "";
  //     if(splitedQues && splitedQues[splitedQues?.length - 1] === "<br />\r\n&nbsp;</p>\r\n"){
  //       splitedQues.pop()
  //       improvedText = splitedQues.map(item=>{
  //         if(item !== undefined && item !== null && item !== ""){
  //           return "<p>"+item
  //         }
  //       }).join("");
  //       return improvedText;
  //     } else {
  //       improvedText = splitedQues.map(item=>{
  //         if(item !== undefined && item !== null && item !== ""){
  //           return "<p>"+item
  //         }
  //       }).join("");
  //       return improvedText;
  //     }
  //   }
  // }

  const renderEvidence = (type) => {
    switch (type?.toLowerCase().replace(/\s+/g, "_")) {
      case "camera":
        return <span className="evidence__type"><i className="far fa-camera" />Photo</span>;
      case "video":
        return <span className="evidence__type"><i className="far fa-video" />Video</span>;
      case "document":
        return <span className="evidence__type"><i className="far fa-file-alt" />Document</span>;
      case "lecturer_observation":
        return <span className="evidence__type"><i className="fal fa-user-check" />Lecturer Observation</span>;  
      default:
        return
    }
  }


  return (
    <div>
      {Object.keys(question).length > 0 && (
        // <div class="card card-quiz card-header-dark">
          // <div class="card-header">Question {quesno}</div>
          // <div class="card-body">
            <div class="">
              <label class="d-block mb-3">
                <MathJax inline dynamic>
                <span class='quiz-ques'>
                  <span>{question.question_type === "Fill in the blank" ? <p>Fill in the Blanks</p> : HtmlParser((question?.question))}</span>
                  {showQuesType}
                </span>
                {question?.description ? <span class='quiz-desc'>{HtmlParser((question?.description)
                    // question.description //.replace(/<\/?[^>]+>/gi, "")
                  )}
                </span> : null}
                
                {question?.question_type == "Practical Criteria" ? <div className="row">
                  {(showDocument && showDocument.length > 0) ? showDocument.map((f, i) => {
                    return <div className="col-md-4 mb-4" key={i}>
                      {RenderAttachList({ fileName: f?.name, size: docsSize[i], filePath: f?.url })}
                    </div>
                  }) : null}
                </div> : null}

                  {/* showing evidence  */}
                  {(question?.question_type == "Practical Criteria") && (question?.evidence_photo || question?.evidence_video || question?.evidence_document || question?.evidence_lecturer_observation) ? <div class='evidence__box'>
                    <span className="practical__evidence__text">
                      Evidence Required
                    </span>
                    <div className="evidence__items">
                      {question?.evidence_photo ? renderEvidence('camera') : null}
                      {question?.evidence_video ? renderEvidence('Video') : null}
                      {question?.evidence_document ? renderEvidence('document') : null}
                      {question?.evidence_lecturer_observation ? renderEvidence('lecturer_observation') : null}
                    </div>
                  </div> : null}

                  {(question?.question_type != "Practical Criteria" && question?.question_type != "Student Declaration") ? <div className="row">
                    {(showDocument && showDocument.length > 0) ? showDocument.map((f, i) => {
                      return <div className="col-md-4 mb-4" key={i}>
                        {RenderAttachList({ fileName: f?.name, size: docsSize[i], filePath: f?.url })}
                      </div>
                    }) : null}
                  </div> : null}
                </MathJax>
              </label>
              <div class="radio-group-custom radion-group-center">
                {/* Multiple Choice Single Answer */}
                <ul className="questions-quiz-option mb-0">
                  {question.question_type == "Multiple Choice Single Answer" &&
                    question?.options.map((item, index) => (
                      <MathJax inline dynamic key={index}>
                      <OptionList
                        grpId={grpId}
                        qInd={qInd}
                        item={item}
                        index={item.oid}
                        question={question}
                        refresh={refresh}
                        setrefresh={setrefresh}
                        refreshprops={refreshprops}
                        answer={singleanswer}
                        setanswer={setsingleanswer}
                      />
                      </MathJax>
                    ))}
                </ul>
                {/* {question.question_type == "Multiple Choice Multiple Answer" &&
                  question?.options.map((item, index) => (
                    <OptionList
                      item={item}
                      index={index}
                      question={question}
                      refresh={refresh}
                      setrefresh={setrefresh}
                      refreshprops={refreshprops}
                      answer={singleanswer}
                      setanswer={setsingleanswer}
                    />
                  ))} */}
                {/* Multiple Choice Multiple Answer */}
                {question.question_type == "Multiple Choice Multiple Answer" &&
                  question?.options.map((item, index) => (
                    <MathJax inline dynamic key={index}>
                    <OptionListCheckbox
                      grpId={grpId}
                      qInd={qInd}
                      item={item}
                      index={item.oid}
                      question={question}
                      refreshprops={refreshprops}
                      answer={answer}
                      setanswer={setanswer}
                    />
                    </MathJax>
                  ))}
                {/* Match the Column */}
                {question.question_type == "Match the Column" && (
                  <div class="select-list-inline">
                    <ul class="list-unstyled">
                      <div className='col-md-6'>
                        {question?.options && question?.options?.map((item, index) => {
                          leftInnerRefs.current[index] = React.createRef();
                          return (
                          <MathJax inline dynamic key={index}>
                          {/* <OptionListMatch
                            item={item}
                            index={index}
                            question={question}
                            refreshprops={refreshprops}
                            setanswer={setanswer}
                            answer={answer}
                            choice={choice}
                            setchoice={setchoice}
                            setLeftInnerHeight={setLeftInnerHeight}
                            finalHeightArr={finalHeightArr}
                            itemst={itemst}
                          /> */}
                              {item?.q_option !== "" ? (
                                <li class="d-flex list-option p-0" style={{ height: `${finalHeightArr[index]}px`, alignItems: "flex-start" }}>
                                  <span ref={leftInnerRefs.current[index]} style={{ padding: "2px 8px" }}>
                                    {ReactHtmlParser(
                                      `<span>${item.q_option}</span>`
                                    )}
                                  </span>
                                </li>
                              ) : null}
                          </MathJax>
                        )})}
                      </div>
                      
                      <div className='col-md-6'>
                        <DragDropContext onDragEnd={(result) => onDragEnd(result, itemst.options)}>
                          <Droppable droppableId={"droppable"+qInd}>
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{...getListStyle(snapshot.isDraggingOver),padding:"0 0 0 12px"}}
                              >
                                {itemst?.options?.map((item, index) => (
                                  <Draggable key={item.oid} draggableId={item.oid?.toString()} index={index} >
                                    {(provided, snapshot) => { 
                                      rightInnerRefs.current[index] = React.createRef();
                                      return (
                                        <div className="mb-12px">
                                          <div
                                            className='match-option'
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{...getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            ), height:`${finalHeightArr[index]}px`}}
                                          >
                                           <span ref={rightInnerRefs.current[index]} style={{display:"block",padding:"2px 8px"}}>
                                              <MathJax inline dynamic>
                                                {item?.q_option_match}
                                              </MathJax>
                                            </span>
                                          </div>
                                      </div>
                                    )}}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    </ul>
                  </div>
                )}
                {/* Short Answer */}
                {question.question_type == "Short Answer" &&
                  question?.options.map((item, index) => (
                    <MathJax inline dynamic key={index}>
                    <OptionShortAnswer
                      grpId={grpId}
                      qInd={qInd}
                      item={item}
                      index={index}
                      question={question}
                      refreshprops={refreshprops}
                      setanswer={setsingleanswer}
                      answer={singleanswer}
                    // editorState={editorState}
                    // setEditorState={setEditorState}
                    />
                    </MathJax>
                  ))}
                {/* Long Answer */}
                {question.question_type == "Long Answer" && (
                  <MathJax inline dynamic>
                  <OptionLongAnswer
                    grpId={grpId}
                    qInd={qInd}
                    question={question}
                    refreshprops={refreshprops}
                    setanswer={settypeanswer}
                    answer={typeanswer}
                  />
                  </MathJax>
                )}
                {/* Fill In The Blank */}
                {question.question_type === "Fill in the blank" &&
                  <MathJax inline dynamic>
                    <FillInTheBlanks
                      grpId={grpId}
                      qInd={qInd}
                      question={question}
                      answer={answer}
                      setanswer={setanswer}
                      refresh={refresh}
                      setrefresh={setrefresh}
                      refreshprops={refreshprops}
                    />
                  </MathJax>}
                {/* Order List */}
                {question.question_type === "Order List" &&
                  <DragDropContext onDragEnd={(result) => onDragEnd(result, itemst.options)}>
                    <Droppable droppableId={"droppable" + qInd}>
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="order__list__wrapper"
                        >
                          {itemst?.options?.map((item, index) => (
                            <Draggable key={item.oid} draggableId={item.oid?.toString()} index={index} >
                              {(provided, snapshot) => (
                                <div
                                  className='order__list__options'
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <MathJax inline dynamic>
                                    {HtmlParser(item.q_option)}
                                  </MathJax>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                }
                {/* Choice Matrix */}
                {question.question_type === "Choice Matrix" &&
                <MathJax inline dynamic>
                  <ChoiceMatrix
                    grpId={grpId}
                    qInd={qInd}
                    question={question}
                    refresh={refresh}
                    setrefresh={setrefresh}
                    refreshprops={refreshprops}
                    answer={answer}
                    setanswer={setanswer}
                  />
                </MathJax>}
                {/* Document Upload */}
                {question.question_type === "Document Upload" &&
                  <DocumentUpload 
                    grpId={grpId}
                    qInd={qInd}
                    question={question}
                    refresh={refresh}
                    setrefresh={setrefresh}
                    refreshprops={refreshprops}
                    answer={answer}
                    setanswer={setanswer}
                  />
                }
                {question.question_type == "Practical Criteria" && <>
                  {(isPracAssessmentLaunched) ? 
                  <>
                   <PracticalAssessementDocUpload
                   grpId={grpId}
                   qInd={qInd}
                   question={question}
                   refresh={refresh}
                   setrefresh={setrefresh}
                   refreshprops={refreshprops}
                   answer={answer}
                   setanswer={setanswer}
                   files={props.files}
                   videoFiles={props.videoFiles}
                   photoFiles={props.photoFiles}
                   docFiles={props.docFiles}
                   setDocFiles={props.setDocFiles}
                   setVideoFiles={props.setVideoFiles}
                   setPhotoFiles={props.setPhotoFiles}
                   setquestion={setquestion}
                   setIsRender={setIsRender}
                   assessmentId={props.assessmentdetails.id}
                   assessmentDetails={props?.assessmentdetails}
                 />
                  {question?.evidence_lecturer_observation ? <div>
                    <div className="d-flex observation__box">
                      <p className="text">Lecturer Observation</p>
                      <div className="custom-toggle-switch ml-2">
                        <label class="switch">
                          <input
                            type="checkbox"
                            disabled={true}
                            checked={question?.lecturer_observation_toggle == 1}
                            // onChange={(e) => {
                            //   const newValue = e.target.checked;
                            //   props.setLecturerObservations((prev) => ({
                            //     ...prev,
                            //     [question.qid]: {
                            //       questionId: question.qid,
                            //       value: newValue ? 1 : 0,
                            //       updated: true
                            //     }
                            //   }));
                            //   let updatedQuestion = {
                            //     ...question,
                            //     lecturer_observation_toggle: newValue ? 1 : 0,
                            //     lecture_observation_updated: true
                            //   }
                            //   dispatch(setQuiz([`qa${question.qid}qa`, updatedQuestion]));
                            //   setquestion(updatedQuestion)
                            //   // const updatedQuizData = quizData.map((quiz) => 
                            //   //   quiz.qid == question.qid
                            //   //     ? { ...quiz, lecturer_observation_toggle: newValue ? 1 : 0 }
                            //   //     : quiz
                            //   // );

                            //   // // Dispatch the updated quizData
                            //   // dispatch(setQuizComplete(updatedQuizData));
                            // }}
                          />
                          <span class="slider slider-round"></span>
                        </label>
                      </div>
                    </div>
                    <p className="text__3a4s"><i className="far fa-history" /><span>To be completed by lecturer</span></p>
                  </div> : null}
                 </>
                 : null}
                </>}
                {question.question_type == "Practical Criteria" && <>
                  {(isAssessmentReview) ? <ReviewPracticalQuestion 
                    grpId={grpId}
                    qInd={qInd}
                    question={question}
                    refresh={refresh}
                    setrefresh={setrefresh}
                    refreshprops={refreshprops}
                    answer={answer}
                    setanswer={setanswer}
                    files={props.files}
                    videoFiles={props.videoFiles}
                    photoFiles={props.photoFiles}
                    docFiles={props.docFiles}
                    setDocFiles={props.setDocFiles}
                    setVideoFiles={props.setVideoFiles}
                    setPhotoFiles={props.setPhotoFiles}
                    setquestion={setquestion}
                    setIsRender={setIsRender}
                    isAdmin={props.isAdmin}
                  /> : null}</>
                }
                {(question?.question_type == "Student Declaration")  ? <StudentSignature isAssessmentReview={isAssessmentReview} formik={props.formik} formikData={props.formikData} question={question} assessmentId={props.assessmentdetails.id} propsData={props}/> : null}
              </div>
            </div>
          // </div>
        // </div>
      )}
    </div>
  );
}

export default DisplayQuestion;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  // console.log('the result of the drag is here ', result)
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 2,
  margin: `0 0 8`,
  // change background colour if dragging
  background: isDragging ? "transparent" : "transparent",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? "transparent" : "transparent",
  padding: '0px 8px ',
});

export {PracticalAssessementDocUpload }