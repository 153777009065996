// brand constants
export const brand = "aie";    //aie, emendy, gdc, learnfast, cadco

//student portal api
export const APP_LINK = 'https://student.cadco.co.za/';
export const API_BASE = "https://newaiesuperadminapi.devtechnosys.tech/api/v1";
export const LOCAL_HOST_DOMAIN = "studentportal.devtechnosys.info"
export const WS_URL = "wss://newaiesuperadminapiwss.devtechnosys.tech/";
export const BASE_URL = "https://newaiesuperadminapi.devtechnosys.tech"
export const STUDENT_FILE_DOWNLOAD = "https://aisuperadmin.devtechnosys.tech/backend/public/";
export const STUDENT_FILE_URI = "https://newaiesuperadminapi.devtechnosys.tech/";
export const DOWNLOAD_FILE_URI = "https://aisuperadmin.devtechnosys.tech/backend/public/";
// export const LOCAL_HOST_DOMAIN = "student.myaie.ac"
// export const WS_URL = "wss://testapi.myaie.ac:3333";
// export const brand = "aie";    //aie, emendy, gdc, learnfast, cadco
//------------------------------------------------------------------
// Production sites - UNCOMMENT BELOW FOR PRODUCTION

// export const API_BASE = "https://testapi.myaie.ac/api/v1";
// export const STUDENT_FILE_DOWNLOAD = "http://myaie.ac/";
// export const STUDENT_FILE_URI = "https://aisuperadmin.devtechnosys.tech:17366/";
// export const DOWNLOAD_FILE_URI = "https://aisuperadmin.devtechnosys.tech/backend/public/";

//Uncomment for AIE, EMD and GDC
export const showWhatsapp = true;
// Uncomment for LF and CADCO
// export const showWhatsapp = false;
// Also comment out relevant portal below 
// export const APP_LINK = 'https://student.myaie.ac/';
// export const APP_LINK = 'https://www.mygdc.ac/';
// export const APP_LINK = 'https://myemendy.ac/';
// export const APP_LINK = 'https://student.learnfast.co.za/';
// export const APP_LINK = 'https://student.cadco.co.za/';


// END PRODUCTION BLOCK
//-------------------------------------------------------------------

//Dev -- UNCOMMENT BELOW FOR DEV BUILD

// export const APP_LINK = 'https://dev.myaie.ac/';
// export const API_BASE = "https://devstudentapi.myaie.ac:17896/api/v1";
// export const STUDENT_FILE_DOWNLOAD = "http://myaie.ac/";
// export const STUDENT_FILE_URI = "";
// export const DOWNLOAD_FILE_URI = "";
// export const showWhatsapp = true;

// //Staging

// export const APP_LINK = 'https://staging.student.myaie.ac/';
// export const API_BASE = "https://stagingstudentapi.myaie.ac:17899/api/v1";
// export const STUDENT_FILE_DOWNLOAD = "http://myaie.ac/";
// export const STUDENT_FILE_URI = "";
// export const DOWNLOAD_FILE_URI = "";
// export const showWhatsapp = true;

// END DEV UNCOMMENT BLOCK

// export const BASE_URL = "https://myaie.ac";
export const MOBILE_REGEX = /^(\+|\d)[0-9]{7,16}$/;
export const EMAIL_REGEX = /^(?!www\.)(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})(?<!\.co)$/;
//global variable
export const ADMIN_PASSWORD = 'aa*S4571';
export const TABLE_ROWS_PER_PAGE = [10, 15, 20, 25, 30, 50, 100];
//Style URLs
export const STYLE_URL_AIE = "student.myaie.ac";
export const STYLE_URL_GDC = "mygdc.ac";
export const STYLE_URL_LOCALHOST_3000 = "localhost:3000";
export const STYLE_URL_LOCALHOST_3001 = "localhost:3001";

//WHEN PUBLISHING:
//Change in package.json the "homepage" to the site page, ex https://www.mygdc.ac

//zip download url hack
export const zipURL = ""; 
export const DOMAIN_URL = "";


export const LANGUAGES = [
    {
        "value": "Afrikaans",
        "label": "Afrikaans"
    },
    {
        "value": "English",
        "label": "English"
    },
    {
        "value": "Ndebele",
        "label": "Ndebele"
    },
    {
        "value": "Nothern Sotho",
        "label": "Nothern Sotho"
    },
    {
        "value": "Other",
        "label": "Other"
    },
    {
        "value": "Southern Sotho",
        "label": "Southern Sotho"
    },
    {
        "value": "Swati",
        "label": "Swati"
    },
    {
        "value": "Tsonga",
        "label": "Tsonga"
    },
    {
        "value": "Tswana",
        "label": "Tswana"
    },
    {
        "value": "Venda",
        "label": "Venda"
    },
    {
        "value": "Xhosa",
        "label": "Xhosa"
    },
    {
        "value": "Zulu",
        "label": "Zulu"
    }
]