import React from "react";

const AppealButton = ({ appeal_button, row_id, onClick ,type}) => {
  // console.log("applea", appeal_button);
  switch (appeal_button) {
    case "appeal":
      return (
        <>
          <button
            onClick={() => onClick(row_id, "view_appeal")}
            disabled={type == 13 || type == 14}
            title="Appeal"
            className={`btn btn-danger assessment_btn_red appeal-button ${(type == 13 || type == 14) && 'disabled'}`}
          >
            <i className="fa fa-star"></i> Appeal
          </button>
        </>
      );
      break;
    case "appeal_view":
      return (
        <>
          <button
            onClick={() => onClick(row_id, "view_appeal")}
            disabled={type == 13 || type == 14}
            title="View Appeal"
            className={`btn btn-warning assessment_btn_yellow appeal-button ${(type == 13 || type == 14) && 'disabled'}`}
          >
            <i className="fa fa-star"></i> View Appeal
          </button>
        </>
      );
      break;
    case "appeal_submit":
      return (
        <>
          <button
            onClick={onClick}
            title="Appeal Submitted"
            className="btn assessment_btn_grey"
            disabled
          >
            <i className="fa fa-star"></i> Appeal Submitted
          </button>
        </>
      );
      break;
    default:
      return <></>;
      break;
  }
}
export default AppealButton;
