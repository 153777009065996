import React, { useEffect, useState } from "react";
import Select from "react-select";

function BachleorModuleFormReadOnly(props) {
  const {
    formik,
    setparentSubjectReSubLength,
    parentSubjectReSubLength,
    setparentSubjectReSubLength1,
    parentSubjectReSubLength1,
    reRegSubject,
    selectedData,
    moduleType,
    progressionData,
    modulesData
  } = props;


  const [fields, setFields] = useState([{ subject: "" }]);
  const [fields1, setFields1] = useState([{ subject: "" }]);
  const [readSelectedData,setReadSelectedData] = useState([])
  let formatted = []
  let obj = {}

  const renderOptions = (data) => {
    const options = data?.map(field => {
      return {
        label: field?.module_name + " (" + field?.credits + " Credits" + ")",
        value: field?.id
      };
    })
    return options;
  }
  const getLabel = (value,data) => {
    // return value.id;
    // const options = data.map(field => {
      return {
        label: value?.module_name + " (" + value?.credits + " Credits" + ")",
        value: value?.id
      };
    // })
    // const selected = options.find(d => d.value == value);
    // return selected;
  }

  function handleAdd(key){
    const values = [...parentSubjectReSubLength[key]]
    values.push("");
    setFields(values);
    setparentSubjectReSubLength({
      ...parentSubjectReSubLength,
      [key]: values
    });
  }

  function handleRemove(i,flag=0,key){
    // const values = [...parentSubjectReSubLength[key]]
    //   if (values.length > 1) {
    //     values.splice(i, 1);
    //     setFields(values);
    //     props.setparentSubjectReSubLength({
    //       ...parentSubjectReSubLength,
    //       [key] : values
    //     });
    //   }
  }
  function handleSubjectChange(i, selectedOption, key) {
    // Ensure that the array for the key exists before modifying
    const values = [...(parentSubjectReSubLength[key] || [])];  // Default to an empty array if it's undefined
    
    // Update the value at the given index (i) with the selectedOption's value
    values[i] = selectedOption ? selectedOption.value : null;  // Using label instead of value
  
    // Update the state and formik field
    setFields(values);
    props.setparentSubjectReSubLength({
      ...parentSubjectReSubLength,
      [key]: values,
    });
  
    // Update Formik field value, storing label as the value
    formik.setFieldValue(`core_arr[${i}]`, selectedOption ? selectedOption.value : null);
  }
  
  

  useEffect(() => {
    if(selectedData){
      setReadSelectedData(JSON.parse(selectedData));
    }
  },[selectedData])
  
  const getTotalCredits = (array) => {
    let totalCredits = 0;
    const totality = array?.map((d,i) => {
      totalCredits += parseInt(d.credits);
      if(i == array.length - 1) return ` (${totalCredits} Credits)`
    })
    return totality;
  }

  // return null
  return (
    <>
    <h5 className="textdanger heading-5 mt-15"> {moduleType.startsWith('c') ? 'Core' : moduleType.startsWith('f') ? 'Foundation' : 'Elective'} Modules</h5>
    {readSelectedData?.map((d, moduleIdx) => (
      <div key={moduleIdx} className="mb-2 mt-3">
        <b>{d?.studyPathName}</b> 
        {getTotalCredits(d?.[Object.keys(d).find(d => isNaN(d) == false)])}
        <span>
          <b className="credits-outstanding ml-2">Credits Outstanding: {d?.outStandingCredits}</b>
        </span>
        {(d?.[Object.keys(d).find(d => isNaN(d) == false)]).map((item, idx) => (
          <div key={idx} className="row">
            <div className="col-sm-6 mt-2 d-flex gap-2">
              {/* <select
                className="form-control"
                name={`subjectReSub_[${idx}].subject`}
                onChange={(e) => handleSubjectChange(idx, e,d.key)}
                onBlur={formik.handleBlur}
                value={item}
                disabled
              >
                <option value="">Select</option>
                <ReReSubject reRegSubject={d.modules.modules} />
              </select> */}
              <div className="form-icon-group">
                  <Select
                      className="form-control custom-select-box "
                      name={`subjectReSub_[${idx}].subject`}
                      onChange={(selectedOption) => handleSubjectChange(idx, selectedOption, d.key)}  // Handle the selection change
                      value={getLabel(item)}// Bind value safely, defaulting to null if undefined
                      isClearable
                      onBlur={formik.handleBlur}
                      isDisabled
                      options={renderOptions(d?.[Object.keys(d).find(d => isNaN(d) == false)])}  // Ensure this function returns options in { value, label } format
                      maxMenuHeight={175}
                  />
                  </div>

              {formik.touched.subjectReSub_ &&
              formik.errors.subjectReSub_ &&
              formik.touched.subjectReSub_[idx] &&
              formik.errors.subjectReSub_[idx] &&
              formik.touched.subjectReSub_[idx].subject &&
              formik.errors.subjectReSub_[idx].subject ? (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {formik.errors.subjectReSub_[idx].subject}
                </div>
              ) : null}
              <a
                href="#"
                className="btn btn-danger opa-5"
                onClick={() => handleRemove(idx,moduleIdx,d.key)}
              >
                <i className="fal fa-trash-alt"></i>
              </a>
            </div>
          </div>
        ))}
        {/* {(formik.values.core_arr[d.key]?.length != d.modules.modules.length) && <div className="form-group m-0">
          <a
            href="#"
            className="btn no-shadow btn-link p-0 btn-additional"
            onClick={() => handleAdd(d.key)}
          >
            Add additional line item <i className="fal fa-plus ml-2"></i> 
          </a>
        </div>} */}
      </div>
    ))}
      
    </>
  );
}

export default BachleorModuleFormReadOnly;
